<template>
  <v-dialog v-model="dialog" persistent style="z-index: 999999;" :max-width="getWidth">
    <v-card elevation="0" class="pt-4 pb-8 px-2" style="position:relative;">
      <mx-preloader :logo="false" :loader-status="preloader"/>
      <v-card-title class="headline mb-9 d-flex align-center justify-space-between">
       <span v-show="show === 'show'">
          {{ $t('customer.crm.company.conversation.tags.title') }}
       </span>
        <v-icon v-show="show === 'show'" class="mr-2" size="36" style="cursor:pointer;" color="red" @click="dialogClose">
          mdi-close-circle-outline
        </v-icon>
      </v-card-title>
      <v-card-text>

        <template v-if="show === 'show'">

          <v-data-table
              hide-default-footer
              :headers="table.headers"
              :items="table.items"
              class="elevation-1 mb-8"
          >
            <template v-slot:item.color="{ item }">
              <div :style="`width: 50px; height: 10px; background: ${item.color}; border-radius: 50px;`"></div>
            </template>
            <template v-slot:item.action="{ item }">
              <v-icon size="20" style="cursor:pointer;" @click="tagEdit(item.id)">mdi-pencil-outline</v-icon>
            </template>
          </v-data-table>


        </template>

        <template v-if="show === 'action'">
          <v-row class="justify-center">
            <v-col cols="12" md="4">
              <v-color-picker hide-mode-switch v-model="form.color" :mode.sync="mode"></v-color-picker>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  class="mb-5"
                  v-model="form.display_text"
                  outlined
                  hide-details
                  type="email"
                  :label="$t('customer.crm.company.conversation.tags.display_text')"
                  :placeholder="$t('customer.crm.company.conversation.tags.display_text')"
                  required
                  :error="errors.display_text!==undefined"
              ></v-text-field>
              <v-text-field
                  class="mb-5"
                  v-model="form.code_text"
                  outlined
                  hide-details
                  type="email"
                  :label="$t('customer.crm.company.conversation.tags.code_text')"
                  :placeholder="$t('customer.crm.company.conversation.tags.code_text')"
                  required
                  :error="errors.code_text!==undefined"
              ></v-text-field>
              <v-text-field
                  disabled
                  class="mb-6"
                  v-model="form.color"
                  outlined
                  hide-details
                  :label="$t('customer.crm.company.conversation.tags.color')"
                  :placeholder="$t('customer.crm.company.conversation.tags.color')"
                  required
                  :error="errors.color!==undefined"
              ></v-text-field>
              <v-btn :loading="btn_loading" :disabled="btn_disable" class="mr-2" color="green white--text" @click="save" elevation="1" large raised tile>
                {{ $t('customer.buttons.save') }}
              </v-btn>
              <v-btn color="grey white--text" @click="abort" elevation="1" large raised tile>
                {{ $t('customer.buttons.abort') }}
              </v-btn>
            </v-col>
          </v-row>
        </template>

        <v-btn v-show="show === 'show'" @click="show = 'action'" color="green" large raised tile>
          <v-icon v-show="show === 'show'" class="mr-2" size="20" style="cursor:pointer;" @click="dialogClose">
            mdi-plus
          </v-icon>
          <span>{{ $t('customer.crm.company.conversation.tags.create') }}</span>
        </v-btn>

      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "CCRMConversationTagsPopup",
  props: {
    open: {
      type: Boolean
    }
  },
  data() {
    return {
      show: 'show',
      preloader: true,
      dialog: false,
      btn_disable: true,
      btn_loading: false,
      mode: 'hexa',
      action: '',
      form: {
        display_text: '',
        code_text: '',
        color: '#333333',
      },
      table: {
        headers: [
          {
            text: this.$t('customer.crm.company.conversation.tags.display_text'),
            sortable: true,
            value: 'display_text',
          },
          {
            text: this.$t('customer.crm.company.conversation.tags.code_text'),
            sortable: false,
            value: 'code_text',
          },
          {
            text: this.$t('customer.crm.company.conversation.tags.color'),
            sortable: false,
            value: 'color',
          },
          {
            text: '',
            sortable: false,
            value: 'action',
          },
        ],
        items: []
      },
      errors: []
    }
  },
  mounted() {
    this.getTags()
  },
  watch: {
    open() {
      this.getTags()
      this.dialog = true
    },
    form: {
      handler(val){
        this.valid()
      },
      deep: true
    }
  },
  computed: {
    getWidth() {
      return window.innerWidth <= 1200 ? '90%' : '1100px'
    }
  },
  methods: {
    valid() {
      if (this.form.display_text !== '' && this.form.code_text !== '' && this.form.color !== '') {
        this.btn_disable = false
      } else {
        this.btn_disable = true
      }
    },
    async getTags() {
      await axios.get('customer/companies/conversation-on/all').then(response => {
        this.table.items = response.data.data
        this.preloader = false
        this.show = 'show'
      }).catch(error => {
        this.btn_disable = false
        console.log(error);
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;
        }
      })
    },
    dialogClose() {
      this.dialog = false
      this.btn_disable = false
      this.show = 'show'
      this.formClear()
      this.$emit('result', true)
    },
    async save() {
      this.btn_disable = true
      this.btn_loading = true

      if (this.action === 'edit') {
        await axios.put(`/customer/companies/conversation-on/${this.form.id}`, this.form).then(response => {
          this.formClear()
          this.show = 'show'
        }).catch(error => {
          this.btn_disable = false
          this.btn_loading = false
          console.log('[ERROR]',error);
        })
      } else {
        await axios.post('customer/companies/conversation-on', this.form).then(response => {
          this.formClear()
          this.getTags()
        }).catch(error => {
          this.btn_disable = false
          this.btn_loading = false
          console.log('[ERROR]',error);
        })
      }
    },
    tagEdit(id) {
      this.form = this.table.items.find(item => item.id === id)
      this.show = 'action'
      this.action = 'edit'
    },
    abort() {
      this.show = 'show'
      this.formClear()
    },
    formClear() {
      this.action = ''
      this.btn_loading = false
      this.form = {
        display_text: '',
        code_text: '',
        color: '#333333',
      }

    }
  }
}
</script>
