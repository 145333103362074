<template>
  <div style="position: relative; min-height: calc(100vh - 100px);">
    <v-card>
      <v-card-title>
        <span>Ansprechpartner</span>
        <v-btn
            style="padding-top: 16px; padding-bottom: 16px;"
            class="ml-auto"
            elevation="2"
            small
            @click="addContactPerson"
            v-if="mxware.can('customer-contact-person-create')"
        >
          <v-icon size="20">mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <v-simple-table v-if="contact_persons.length > 0">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center" style="width: 100px;"></th>
            <th class="text-left">
              {{ $t('employee.contact_person.first_name') }}
            </th>
            <th class="text-left">
              {{ $t('employee.contact_person.last_name') }}
            </th>
            <th class="text-left">
              {{ $t('employee.contact_person.position') }}
            </th>
            <th class="text-left">
              {{ $t('employee.contact_person.email') }}
            </th>
            <th class="text-left">
              {{ $t('employee.contact_person.phone') }}
            </th>
          </tr>
          <th></th>
          </thead>
          <tbody>
          <tr v-for="item in contact_persons" :key="item.employee_uid">
            <td class="text-center"><img width="30" class="rounded" :src="getAvatar(item.avatar)"></td>
            <td>{{ item.first_name }}</td>
            <td>{{ item.last_name }}</td>
            <td>{{ item.position }}</td>
            <td>{{ item.contact_email }}</td>
            <td>{{ item.contact_phone }}</td>
            <td>
              <span v-if="mxware.can('customer-contact-person-update')" style="cursor: pointer;" @click="contactEdit(item)">
                <v-icon color="blue" size="16">mdi-pencil-outline</v-icon>
              </span>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

    <v-dialog v-model="dialog_create_contact" persistent max-width="900">
      <ContactPersonCreateForm v-if="dialog_create_contact" :contact_persons="contact_persons" @dialogClose="dialogClose" @getPerson="getPerson" />
    </v-dialog>

    <v-dialog v-model="dialog_edit_contact" persistent max-width="900">
      <ContactPersonEditForm
          v-if="dialog_edit_contact"
          :contact_person="contact_person"
          @dialogClose="dialogClose"
          @getUpdatedPerson="getUpdatedPerson"
          @contactDeleted="contactDeleted"
      />
    </v-dialog>

  </div>
</template>

<script>
import ContactPersonCreateForm from "./CustomerCRMContactPersonCreateForm";
import ContactPersonEditForm from "./CustomerCRMContactPersonEditForm";
import options from "../../../../options";

export default {
  name: "CustomerCRMPersonalData",
  components: {ContactPersonCreateForm, ContactPersonEditForm},
  data() {
    return {
      dialog_create_contact: false,
      dialog_edit_contact: false,
      contact_person: {},
      contact_persons: [],
    }
  },
  created() {
    this.contact_persons = JSON.parse(this.$store.getters.customerCrm.contact_persons).data
  },
  methods: {
    getAvatar(avatar) {
      return (avatar !== null) ?  options.avatar_url + avatar : '/assets/images/avatar.jpg'
    },
    contactDeleted(id) {
      if (this.contact_persons.length > 1) {
        let index = this.contact_persons.findIndex(c => c.id === id)
        this.contact_persons.splice(index,1)
      } else {
        this.contact_persons = []
      }
      this.dialog_edit_contact = false
    },
    addContactPerson() {
      this.dialog_create_contact = true
    },
    contactEdit(contact) {
      this.contact_person = contact
      this.dialog_edit_contact = true
    },
    dialogClose() {
      this.dialog_create_contact = false
      this.dialog_edit_contact = false
    },
    getPerson(e) {
      this.contact_persons.push(e)
    },
    getUpdatedPerson(e) {
      let index = this.contact_persons.findIndex(c => c.id === e.id)
      this.contact_persons[index] = e
    },
  }
}
</script>

