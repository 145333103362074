<template>
  <v-card elevation="0" class="pb-10 px-2">
    <v-row>
      <v-col cols="12" class="d-flex justify-space-between align-center">
        <h3>{{ $t('customer.crm.company.conversation.title') }}</h3>
        <div>
          <v-btn color="green mr-2" dark raised tile @click="getConversations">
            <v-icon size="18" class="mr-2">mdi-reload</v-icon>
            {{ $t('customer.crm.company.conversation.tags.reload') }}
          </v-btn>
          <v-btn color="accent" raised tile @click="open_tags_dialog = !open_tags_dialog">
            <v-icon class="mr-2" size="18">mdi-eye-outline</v-icon>
            {{ $t('customer.crm.company.conversation.tags.show') }}
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <v-row>
          <v-col cols="12">
            <strong  ref="conversationTop">
              {{ $t('customer.crm.company.conversation.employee') }}
            </strong>
            <span>
              {{ this.form.employee }}
            </span>
          </v-col>

          <v-col cols="12" md="6">
            <v-menu
                v-model="date.date_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="computedDateFormatted"
                    label="Date"
                    hide-details
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="date.date"
                  no-title
                  @input="date.date_menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" md="6">
            <v-menu
                ref="menu"
                v-model="date.time_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="date.time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="date.time"
                    label="Zeit"
                    readonly
                    outlined
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                  v-if="date.time_menu"
                  v-model="date.time"
                  format="24hr"
                  full-width
                  @click:minute="$refs.menu.save(date.time)"
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="12">
            <v-select v-model="form.conversation_on_id"
                      :label="$t('customer.crm.company.conversation.form.tags') + ' *'"
                      :placeholder="$t('customer.crm.company.conversation.form.tags_placeholder')"
                      :items="conversation_ons"
                      item-value="id"
                      item-text="display_text"
                      outlined
                      hide-details
                      :append-icon="'mdi-chevron-down'"
                      :menu-props="{ bottom: true, offsetY: true }"
            ></v-select>
          </v-col>
          <v-col cols="12" class="d-flex align-center">
            <v-text-field
                v-model="form.contact_person"
                outlined
                hide-details
                :label="$t('customer.crm.company.conversation.form.contact_person')"
                :placeholder="$t('customer.crm.company.conversation.form.contact_person')"
                required
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-textarea
                outlined
                :label="$t('customer.crm.company.conversation.form.description') + ' *'"
                v-model="form.description"
                rows="3"
                hide-details
                :error-messages="errors.description"
            ></v-textarea>
          </v-col>
          <v-col cols="12" md="6">
            <v-btn
                style="width: 100%; padding: 28px;"
                elevation="0"
                color="green"
                @click="save"
                :loading="btn_save_loading"
                :disabled="btn_save_disabled"
            >
              {{ $t('document.category.buttons.save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="8" class="d-flex" :style="`height: ${height}px; overflow-x: auto`">
        <div class="mx-conversation-items">
          <div class="mx-conversation-item" v-for="[title, conversation] in Object.entries(conversations)">
            <div class="mx-conversation-date">
              <span>{{ title }}</span>
            </div>
            <div class="mx-conversation-content">
              <div class="mx-conversation-content-item" v-for="item in conversation">
                <div class="mx-conversation-content-left">
                  <v-icon class="mx-conversation-content-left__icon" size="20">mdi-clock-time-eight-outline</v-icon>
                  <span class="mx-conversation-content-left__text">{{ item.time }}</span>
                </div>
                <div class="mx-conversation-content-right" :style="`border-left-color: ${item.on.color}`">
                  <div class="mx-conversation-content-right__top">
                    <div class="mx-conversation-content-right__on" :style="`color: ${item.on.color}`">
                      {{ item.on.display_text }}
                    </div>
                    <div class="mx-conversation-content-right__block">
                      <span class="mx-conversation-content-right__block-b">{{ $t('customer.crm.company.conversation.employee') }}</span>
                      <span class="mx-conversation-content-right__block-t">{{ item.employee }}</span>
                    </div>
                    <div class="mx-conversation-content-right__block">
                      <span class="mx-conversation-content-right__block-b">{{ $t('customer.crm.company.conversation.contact_person') }} </span>
                      <span class="mx-conversation-content-right__block-t">{{ item.contact_person }}</span>
                    </div>
                  </div>
                  <div class="mx-conversation-content-right__text">
                    {{ item.description }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <CCRMConversationTagsPopup @result="getConversations" :open="open_tags_dialog" />

  </v-card>
</template>

<script>

import axios from "axios";
import moment from "moment";
import CCRMConversationTagsPopup from "./CCRMConversationTagsPopup";

export default {
  name: "CCRMConversation",
  props: {
    company_id: {
      required: true
    }
  },
  components: { CCRMConversationTagsPopup },
  data() {
    return {
      open_tags_dialog: false,
      form: {
        company_id: "",
        employee: "",
        employee_id: "",
        contact_person: "",
        description: "",
        conversation_on_id: null,
        date: "",
      },

      date: {
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        date_menu: false,


        time: moment().format('HH:mm'),
        time_menu: false,
      },

      conversations:{},
      conversation_ons: [],
      conversations1: {
        "10.01.2022": [
          {
            "id": 11,
            "company_id": 9,
            "employee_id": 2,
            "employee": "Alexander Bechthold",
            "contact_person": "Contact person 1",
            "description": "As opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page",
            "status": 0,
            "date": "2022-01-10 17:37",
            "time": "17:37",
            "on": {
              "id": 1,
              "display_text": "Postalisch",
              "color": "#6CDD77"
            }
          },
          {
            "id": 5,
            "company_id": 9,
            "employee_id": 2,
            "employee": "Alexander Bechthold",
            "contact_person": "Contact person 1",
            "description": "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form",
            "status": 0,
            "date": "2022-01-10 14:03",
            "time": "14:03",
            "on": {
              "id": 1,
              "display_text": "Postalisch",
              "color": "#6CDD77"
            }
          },
          {
            "id": 2,
            "company_id": 9,
            "employee_id": 2,
            "employee": "Alexander Bechthold",
            "contact_person": "Contact person 1",
            "description": "Is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
            "status": 0,
            "date": "2022-01-10 12:46",
            "time": "12:46",
            "on": {
              "id": 1,
              "display_text": "Postalisch",
              "color": "#6CDD77"
            }
          },
          {
            "id": 3,
            "company_id": 9,
            "employee_id": 2,
            "employee": "Alexander Bechthold",
            "contact_person": "Contact person 1",
            "description": "All the Lorem Ipsum generators on the Internet tend to repeat predefined",
            "status": 0,
            "date": "2022-01-10 12:46",
            "time": "12:46",
            "on": {
              "id": 1,
              "display_text": "Postalisch",
              "color": "#6CDD77"
            }
          }
        ],
        "09.01.2022": [
          {
            "id": 9,
            "company_id": 9,
            "employee_id": 2,
            "employee": "Alexander Bechthold",
            "contact_person": "Contact person 1",
            "description": "To generate Lorem Ipsum which looks reasonable.",
            "status": 0,
            "date": "2022-01-09 13:11",
            "time": "13:11",
            "on": {
              "id": 1,
              "display_text": "Postalisch",
              "color": "#6CDD77"
            }
          },
          {
            "id": 10,
            "company_id": 9,
            "employee_id": 2,
            "employee": "Alexander Bechthold",
            "contact_person": "Contact person 1",
            "description": "The standard chunk of Lorem Ipsum used since the 1500s",
            "status": 0,
            "date": "2022-01-09 13:11",
            "time": "13:11",
            "on": {
              "id": 1,
              "display_text": "Postalisch",
              "color": "#6CDD77"
            }
          },
          {
            "id": 4,
            "company_id": 9,
            "employee_id": 2,
            "employee": "Alexander Bechthold",
            "contact_person": "Contact person 1",
            "description": "Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word.",
            "status": 0,
            "date": "2022-01-09 10:12",
            "time": "10:12",
            "on": {
              "id": 1,
              "display_text": "Postalisch",
              "color": "#6CDD77"
            }
          }
        ],
        "26.12.2021": [
          {
            "id": 7,
            "company_id": 9,
            "employee_id": 2,
            "employee": "Alexander Bechthold",
            "contact_person": "Contact person 1",
            "description": "If you are going to use a passage of Lorem Ipsum, you need to be sure",
            "status": 0,
            "date": "2021-12-26 13:11",
            "time": "13:11",
            "on": {
              "id": 1,
              "display_text": "Postalisch",
              "color": "#6CDD77"
            }
          },
          {
            "id": 6,
            "company_id": 9,
            "employee_id": 2,
            "employee": "Alexander Bechthold",
            "contact_person": "Contact person 1",
            "description": " It was popularised in the 1960s with the release of Letraset sheets containing.",
            "status": 0,
            "date": "2021-12-26 11:11",
            "time": "11:11",
            "on": {
              "id": 1,
              "display_text": "Postalisch",
              "color": "#6CDD77"
            }
          }
        ],
        "09.01.2021": [
          {
            "id": 8,
            "company_id": 9,
            "employee_id": 2,
            "employee": "Alexander Bechthold",
            "contact_person": "Contact person 1",
            "description": "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer.",
            "status": 0,
            "date": "2021-01-09 13:11",
            "time": "13:11",
            "on": {
              "id": 1,
              "display_text": "Postalisch",
              "color": "#6CDD77"
            }
          }
        ]
      },
      conversation_ons1: [
        {
          "id": 1,
          "display_text": "Postalisch",
          "color": "#6CDD77"
        }
      ],
      height: 0,
      errors: {},
      preloader: true,
      btn_save_loading: false,
      btn_save_disabled: true
    }
  },
  watch: {
    form: {
      handler(val){
        this.valid()
      },
      deep: true
    },
    // date: {
    //   handler(val){
    //     this.getDate()
    //   },
    //   deep: true
    // },
  },
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.date.date)
    },
  },
  created() {
    this.getConversations()

    setTimeout( () => {
      const conversationTop = parseInt(this.$refs.conversationTop.getBoundingClientRect().top + 20)
      this.height = window.innerHeight - conversationTop
    }, 1)

    this.form.employee_id = this.$store.getters.user.id
    this.form.employee = this.$store.getters.user.first_name + ' ' + this.$store.getters.user.last_name
  },
  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    valid() {
      if (this.form.description !== '' && this.form.conversation_on_id !== null) {
        this.btn_save_disabled = false
      } else {
        this.btn_save_disabled = true
      }
    },
    async getConversations() {
      this.form.company_id = this.company_id
      await axios.get(`/customer/companies/conversation-history/${this.company_id}`).then(response => {
        this.conversations = response.data.data.conversations
        this.conversation_ons = response.data.data.conversation_ons
        this.preloader = false
      }).catch(error => {
        switch (error.response.status) {
          case 500:
            console.log('[ERROR 500]', error);
            setTimeout(this.getConversations, 30)
            break;
        }
      })
    },
    async save() {
      this.btn_save_loading = true
      this.form.date = this.date.date + ' ' + this.date.time
      await axios.post(`/customer/companies/conversation-history`, this.form).then(response => {
        this.getConversations()
        this.btn_save_loading = false
        this.form.description = ''
        this.form.contact_person = ''
        this.form.conversation_on_id = null
      })
    }
  }
}
</script>


<style lang="scss">

.mx-conversation-items {
  width: 100%;
  .mx-conversation-item {
    display: flex;
    //align-items: center;
    margin-bottom: 30px;
    border: 1px dashed #cecece;
    padding: 8px 18px;
    border-radius: 6px;

    .mx-conversation-date {
      margin-right: 10px;
      font-weight: 700;
      font-size: 18px;
      //border-right: 2px solid #cecece;
      display: flex;
      align-items: center;
      padding-right: 20px;
      position: relative;

      span {
        position: sticky;
        top: 0;
      }
      //&:before {
      //  content: '';
      //  height: 40px;
      //  width: 2px;
      //  background: #fff;
      //  position: absolute;
      //  top: 0;
      //  right: -2px;
      //}
      //&:after {
      //  content: '';
      //  height: 40px;
      //  width: 2px;
      //  background: #fff;
      //  position: absolute;
      //  bottom: 0;
      //  right: -2px;
      //}
    }

    .mx-conversation-content {
      width: 100%;
      .mx-conversation-content-item {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        background: #F8F8F8;
        border-radius: 2px;
        padding: 10px 0;

        &:last-child {
          margin-bottom: 0;
        }

        .mx-conversation-content-left {
          padding: 0 18px;
          display: flex;
          position: relative;
          //&:before {
          //  content: '';
          //  width: 20px;
          //  height: 2px;
          //  position: absolute;
          //  top: 29px;
          //  left: 0;
          //  background: red;
          //}
          .mx-conversation-content-left__icon {
            margin-right: 10px;
            color: #5B5B5B;
          }

          .mx-conversation-content-left__text {
            font-weight: 700;
            font-size: 16px;
            color: #5B5B5B;
          }
        }

        .mx-conversation-content-right {
          border-left: 2px solid;
          padding: 5px 20px;

          &__top {
            display: flex;
            align-items: center;
            margin-bottom: 6px;
          }

          &__text {
            font-size: 15px;
            line-height: 20px;
          }

          .mx-conversation-content-right__on {
            font-size: 12px;
            font-weight: 500;
            margin-top: 3px;
          }

          .mx-conversation-content-right__block {
            color: #5B5B5B;
            margin-left: 20px;

            &-b {
              font-size: 12px;
              font-weight: 500;
              margin-right: 5px;
            }

            &-t {
              font-size: 12px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}
</style>
