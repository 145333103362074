<template>
  <div style="height: calc(100vh - 130px); position:relative;">
    <mx-preloader :loader-status="preloader"/>
    <v-container fluid>
      <v-row class="mt-6">
        <v-col md="3" lg="2">
          <v-btn
              v-if="mxware.can('document-create')"
              :to="{name: 'document.create', params: {'customer_id': $route.params.id}}"
              color="primary white--text"
              class="rounded-0 mb-6"
              x-large block elevation="1"
          >
            {{ $t('document.buttons.document_create') }}
          </v-btn>
          <v-card class="mx-auto" max-width="400">

            <div class="mx-menu-list">
              <button class="mx-menu-list__item"
                  @click="getCategoryDocument(category.id)"
                  v-for="category in categories"
                  :key="category.id"
                  :disabled="category.disabled"
                  :class="{'active': category_id === category.id - 1 && !customer_documents}"
              >
                <v-icon size="24" color="darken-4" class="mr-2 ml-2">mdi-folder-outline</v-icon>
                {{ category.name }}
              </button>

              <button class="mx-menu-list__item" :class="{'active': customer_documents}" @click="getCustomerDocuments">
                <v-icon size="24" color="darken-4" class="mr-2 ml-2">mdi-folder-outline</v-icon>
                {{ $t('document.menu.customer_documents') }}
              </button>
            </div>

          </v-card>
        </v-col>
        <v-col md="9" lg="10" >
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label=" $t('document.table.search')"
              hide-details
              solo
              class="mb-6 elevation"
              height="53"
          ></v-text-field>
          <v-data-table :headers="headers" :items="getDocuments" :items-per-page="12" :search="search">
            <template v-slot:item.my="{ item }">
              <small
                  v-if="item.created_customer_id >= 1"
                  style="display: block;width: 7px; height: 7px; background: #e3e3e3; border-radius: 2px;"
              ></small>
            </template>
            <template v-slot:item.name="{ item }">
              <a target="_blank" :href="options.document_url + item.path">{{ item.name }}</a>
            </template>
            <template v-slot:item.file_type="{ item }">
              <small>{{ getFileType(item.file_type) }}</small>
            </template>
            <template v-slot:item.category_id="{ item }">
              {{ getCategory(item.category_id) }}
            </template>
            <template v-slot:item.for="{ item }">
              {{ getFor(item.for) }}
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn v-if="mxware.can('document-update')" text elevation="0" small :to="{name: 'document.edit', params: {id: item.id}}">
                <v-icon size="16" color="blue-grey darken-4">mdi-pencil-outline</v-icon>
              </v-btn>
              <v-btn v-if="mxware.can('document-delete')" text elevation="0" small @click="dialogOpen(item)">
                <v-icon size="16" color="red darken-4">mdi-trash-can-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="500">
        <v-card class="pa-4">
          <v-card-title class="headline mb-9">
            <small style="width: 100%; text-align: center; font-size: 14px; color: rgba(0, 0, 0, 0.6)">{{ selected_document.name }}</small>
            <h4 style="text-align: center; width: 100%;">
              {{ $t('document.titles.document_delete') }}
            </h4>
          </v-card-title>
          <v-card-actions class="d-flex justify-center">
            <v-btn color="red" dark @click="documentDelete">
              {{ $t('document.buttons.delete') }}
            </v-btn>
            <v-btn color="grey" dark @click="dialogClose">
              {{ $t('document.buttons.abort') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Documents",
  data() {
    return {
      customer_documents: false,
      preloader: true,
      dialog: false,
      category_id: 0,
      search: '',
      headers:[
        { text: '', value: 'my', width: 10, sortable: false },
        { text: this.$t('document.table.name'), value: 'name' },
        { text: this.$t('document.table.type'), value: 'file_type' },
        { text: this.$t('document.table.category'), value: 'category_id' },
        { text: this.$t('document.table.create_at'), value: 'created_at' },
        { text: this.$t('document.table.update_at'), value: 'updated_at' },
        { text: '', value: 'action', sortable: false },
      ],
      categories:[],
      documents: [],
      selected_document: {},
    }
  },
  created () {
    this.getCategories()
  },
  computed: {
    wHeight() {
      return window.innerHeight
    },
    getDocuments() {
      if (this.category_id === 0 && !this.customer_documents) {
        return this.documents
      }

      if (this.customer_documents) {
        return this.documents.filter( document => {
          return document.created_customer_id  >= 1
        })
      }

      return this.documents.filter( document => {
        return document.category_id === this.category_id + 1
      })
    },
  },
  methods: {
    getCategoryDocument(id) {
      this.customer_documents = false
      this.category_id = id -1
    },
    getCustomerDocuments() {
      this.customer_documents = true
    },
    dialogOpen(document) {
      this.selected_document = document
      this.dialog = true
    },
    dialogClose() {
      this.selected_document = {}
      this.dialog = false
    },
    documentDelete() {
      this.$store.dispatch('documentDelete', this.selected_document.id).then(() => {
        this.documents = this.$store.getters.documents
        this.dialogClose()
      })
    },
    getAllDocuments() {
      if (this.$store.getters.customersCrm.data === undefined) {
        this.$store.dispatch('customersCrm').then(() => {
           let customer = this.$store.getters.customersCrm.data.find(c => c.id === parseInt(this.$route.params.id))
          this.getAllCustomerDocuments(customer)
        })
      } else  {
        let customer = this.$store.getters.customersCrm.data.find(c => c.id === parseInt(this.$route.params.id))
        this.getAllCustomerDocuments(customer)
      }
    },
    getAllCustomerDocuments(customer) {
      this.$store.dispatch('customerDocuments', customer.uid).then(() => {
        this.documents = this.$store.getters.documents
        this.categories.map(category => {
          category['disabled'] = this.documents.findIndex(document => document.category_id === category.id) === -1 && category.id !== 1
          return category
        })

        setTimeout(() => {this.preloader = false}, 300)
      })
    },
    async getCategories() {
      await this.$store.dispatch('documentCategories').then(() => {
        this.categories = this.$store.getters.document_categories
        this.getAllDocuments()
      })
    },
    getCategory(category_id) {
      return this.categories.find(category => category.id === category_id).name
    },
    getFileType(type) {
      return type.toUpperCase()
    },
    getFor(fr) {
      return this.$t(`document.for.${fr}`)
    }
  }
}
</script>
