<template>
  <v-dialog v-model="dialog" persistent style="z-index: 999999;" :max-width="getWidth">
    <v-card elevation="0" class="pt-4 pb-8 px-2">
      <v-card-title class="headline mb-9">
        {{ $t('customer.crm.company.edit_location') }}
      </v-card-title>
      <v-card-text>
        <v-row>
<!--          <v-col md="12" lg="6" class="mb-8">-->
<!--            <v-row>-->
<!--              <v-col cols="12">-->
<!--                <v-text-field-->
<!--                    v-model="form.country"-->
<!--                    outlined-->
<!--                    hide-details-->
<!--                    type="email"-->
<!--                    :label="$t('customer.crm.company.country')"-->
<!--                    :placeholder="$t('customer.crm.company.country')"-->
<!--                    required-->
<!--                    :error="errors.country!==undefined"-->
<!--                ></v-text-field>-->
<!--              </v-col>-->
<!--              <v-col cols="12">-->
<!--                <v-text-field-->
<!--                    v-model="form.city"-->
<!--                    outlined-->
<!--                    hide-details-->
<!--                    type="email"-->
<!--                    :label="$t('customer.crm.company.city')"-->
<!--                    :placeholder="$t('customer.crm.company.city')"-->
<!--                    required-->
<!--                    :error="errors.city!==undefined"-->
<!--                ></v-text-field>-->
<!--              </v-col>-->
<!--            </v-row>-->
<!--            <v-row>-->
<!--              <v-col cols="12">-->
<!--                <v-text-field-->
<!--                    v-model="form.postcode"-->
<!--                    outlined-->
<!--                    hide-details-->
<!--                    type="email"-->
<!--                    :label="$t('customer.crm.company.postcode')"-->
<!--                    :placeholder="$t('customer.crm.company.postcode')"-->
<!--                    required-->
<!--                    :error="errors.postcode!==undefined"-->
<!--                ></v-text-field>-->
<!--              </v-col>-->
<!--              <v-col cols="12">-->
<!--                <v-text-field-->
<!--                    v-model="form.street"-->
<!--                    outlined-->
<!--                    hide-details-->
<!--                    type="email"-->
<!--                    :label="$t('customer.crm.company.street')"-->
<!--                    :placeholder="$t('customer.crm.company.street')"-->
<!--                    required-->
<!--                    :error="errors.street!==undefined"-->
<!--                ></v-text-field>-->
<!--              </v-col>-->
<!--            </v-row>-->
<!--          </v-col>-->

          <v-col md="12" lg="6" class="mb-8">
            <v-row>
              <v-col cols="12">
                <v-text-field
                    v-model="form.address_short_name"
                    outlined
                    hide-details
                    type="email"
                    :label="$t('customer.crm.company.address_short_names')"
                    :placeholder="$t('customer.crm.company.address_short_names')"
                    required
                    :error="errors.city!==undefined"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    v-model="form.street"
                    outlined
                    hide-details
                    type="email"
                    :label="$t('customer.crm.company.street')"
                    :placeholder="$t('customer.crm.company.street')"
                    required
                    :error="errors.street!==undefined"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    v-model="form.postcode"
                    outlined
                    hide-details
                    type="email"
                    :label="$t('customer.crm.company.postcode')"
                    :placeholder="$t('customer.crm.company.postcode')"
                    required
                    :error="errors.postcode!==undefined"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    v-model="form.city"
                    outlined
                    hide-details
                    type="email"
                    :label="$t('customer.crm.company.city')"
                    :placeholder="$t('customer.crm.company.city')"
                    required
                    :error="errors.city!==undefined"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    v-model="form.country"
                    outlined
                    hide-details
                    type="email"
                    :label="$t('customer.crm.company.country')"
                    :placeholder="$t('customer.crm.company.country')"
                    required
                    :error="errors.country!==undefined"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col md="12" lg="6">
            <div class="mb-3 mx-hour-wrapper">
              <div>
                <div class="mx-hour" v-for="element in hour_elements">
                  <strong class="mx-hour__title">{{ $t(`customer.crm.company.day_of_week.${element}`) }}</strong>
                  <div class="mx-hour__inputs" v-show="form.opening_hours[element].open === '1'">
                    <input type="text" class="form-control mx-hour__inputs-input" v-mask="'99:99'" placeholder="08:30" v-model="form.opening_hours[element].from">
                    <span class="mx-hour__inputs-tr"> - </span>
                    <input type="text" class="form-control mx-hour__inputs-input" v-mask="'99:99'" placeholder="19:30" v-model="form.opening_hours[element].to">
                  </div>
                  <div class="mx-hour__inputs" v-show="form.opening_hours[element].open === '0'">
                    <span class="mx-hour__closed">{{ $t('company.closed') }}</span>
                  </div>
                  <span @click="form.opening_hours[element].open = closedDay(form.opening_hours[element].open)" class="mx-hour__close">
                     <v-icon size="16" color="blue-grey darken-4">mdi-lock-outline</v-icon>
                   </span>
                  <span v-if="isDown(element)" class="mx-hour__down" @click="allW">
                     <v-icon size="16" color="blue-grey darken-4">mdi-arrow-down</v-icon>
                   </span>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between mr-2">
        <v-checkbox
            class="ml-2"
            :disabled="form.is_main === 1"
            v-model="form.is_main"
            :label="$t('customer.crm.company.main_address')"
            :false-value="0"
            :true-value="1"
        ></v-checkbox>
        <div>
          <v-btn :loading="btn_disable" :disabled="btn_disable" class="mr-2" color="green white--text" @click="companyAddressCreate" elevation="1" large raised tile>
            {{ $t('customer.buttons.save') }}
          </v-btn>
          <v-btn color="grey white--text" @click="dialogClose" elevation="1" large raised tile>
            {{ $t('customer.buttons.abort') }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CCRMCompanyAddressCreate",
  props: {
    open: {
      type: Boolean
    },
    company_id: {
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      btn_disable: false,
      hour_elements: ['mo', 'di', 'mi', 'do', 'fr', 'sa', 'so'],
      form: {
        company_id: this.company_id,
        country:  this.$t('customer.crm.company.countries.germany'),
        city: '',
        postcode: '',
        street: '',
        is_main: 0,
        address_short_name: null,
        opening_hours: {
          mo: {from: 'null', to: 'null', open: '1'},
          di: {from: 'null', to: 'null', open: '1'},
          mi: {from: 'null', to: 'null', open: '1'},
          do: {from: 'null', to: 'null', open: '1'},
          fr: {from: 'null', to: 'null', open: '1'},
          sa: {from: 'null', to: 'null', open: '1'},
          so: {from: 'null', to: 'null', open: '1'}
        }
      },
      errors: []
    }
  },
  watch: {
    open() {
      this.dialog = true
      this.form.company_id = this.company_id
    },
  },
  computed: {
    getWidth() {
      return window.innerWidth <= 1200 ? '90%' : '1100px'
    }
  },
  methods: {
    isDown(element) {
      return element === 'mo' && (this.form.opening_hours['mo'].from !== 'null' && this.form.opening_hours['mo'].to !== 'null')
    },
    allW() {
      for (const hourElementsKey in this.hour_elements) {
        if (this.hour_elements[hourElementsKey] !== 'mo') {
          this.form.opening_hours[this.hour_elements[hourElementsKey]].from = this.form.opening_hours['mo'].from
          this.form.opening_hours[this.hour_elements[hourElementsKey]].to = this.form.opening_hours['mo'].to
          this.form.opening_hours[this.hour_elements[hourElementsKey]].open = '1'
        }
      }
    },
    dialogClose() {
      this.dialog = false
      this.btn_disable = false
      this.form = {
        company_id: null,
        country:  '',
        city: '',
        postcode: '',
        street: '',
        is_main: 0,
        opening_hours: {
          mo: {from: 'null', to: 'null', open: '1'},
          di: {from: 'null', to: 'null', open: '1'},
          mi: {from: 'null', to: 'null', open: '1'},
          do: {from: 'null', to: 'null', open: '1'},
          fr: {from: 'null', to: 'null', open: '1'},
          sa: {from: 'null', to: 'null', open: '1'},
          so: {from: 'null', to: 'null', open: '1'}
        }
      }
    },
    closedDay(bool) {
      return bool === '1'? '0': '1'
    },
    companyAddressCreate() {
      this.btn_disable = true
      this.$store.dispatch('companyAddressCreate', this.form).then(() => {
        this.$emit('result', this.form)
        this.dialogClose()
      }).catch(error => {
        this.btn_disable = false
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;
        }
      })
    }
  }
}
</script>
