<template>
  <div style="position: relative; min-height: calc(100vh - 180px)">
    <v-row  class="mt-6 mx-2">
      <v-col cols="12">
        <div class="d-flex justify-space-between align-center" style="min-height: 42px;">
          <h3>{{ company.company_name }}</h3>
          <v-btn
              v-if="top_menu.index === 0 && mxware.can('customer-company-delete')"
              style="padding-top: 20px; padding-bottom: 20px;"
              class="ml-1"
              elevation="2"
              color="red"
              dark
              medium
              @click="companyDeleteDialog(company)"
          >
            <v-icon size="28">mdi-trash-can-outline</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
   <v-row class="mt-6 mx-2">
     <v-col sm="12" md="3" lg="2">
       <v-tabs class="mx-tab" v-model="top_menu.index" :vertical="changeVertical" active-class="mx-tab_active">
         <v-tab :disabled="$store.getters.customerCrmEditDisabled" v-for="tab in top_menu.tabs" :key="tab.slug">
           {{ tab.name }}
         </v-tab>
       </v-tabs>
     </v-col>
     <v-col sm="12" md="9" lg="10">
       <v-tabs-items v-model="top_menu.index">
         <v-tab-item :key="'company'">
           <CCRMCompanyEdit :company="company"/>
         </v-tab-item>
         <v-tab-item :key="'address'">
           <CCRMCompanyAddress :company_id="company.id" :addresses="company.addresses"/>
         </v-tab-item>
         <v-tab-item :key="'contact_person'">
           <CCRMCompanyContactPersons :company="company" :contact_persons="company.persons" />
         </v-tab-item>
         <v-tab-item :key="'access_data'">
           <CCRMCustomerAccessDataList :company="company" :data_list="company.customer_access_data" />
         </v-tab-item>
         <v-tab-item :key="'faq'">
           <CCRMCompanyFaq v-if="top_menu.index === 4" :company="company"/>
         </v-tab-item>
         <v-tab-item :key="'conversation'">
           <CCRMConversation v-if="top_menu.index === 5" :company_id="company.id"/>
<!--           <CCRMConversation :company_id="company.id"/>-->
         </v-tab-item>
         <v-tab-item :key="'notice'">
           <NoticeList :customer_id="company.customer_id" :notices="notices" />
         </v-tab-item>
       </v-tabs-items>
     </v-col>
   </v-row>
    <v-row justify="center">
      <v-dialog v-model="company_delete_dialog" persistent max-width="500">
        <v-card class="px-4 pt-3 pb-6">
          <v-card-title class="headline mb-9">
            <h4 style="width: 100%; margin-bottom: -15px;">
              {{ $t('customer.crm.company.prompt.company.title', {company_name: selected_company.company_name}) }}
            </h4>
          </v-card-title>
          <v-card-subtitle>
            {{ $t('customer.crm.company.prompt.company.subtitle') }}
          </v-card-subtitle>
          <v-card-actions class="d-flex justify-end mr-2">
            <v-btn color="red" dark @click="companyDelete" tile>
              {{ $t('customer.buttons.delete') }}
            </v-btn>
            <v-btn color="grey" dark @click="company_delete_dialog = false" tile>
              {{ $t('customer.buttons.abort') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import CCRMCompanyEdit from "@/components/Customer/Crm/Company/_company/CCRMCompanyEdit";
import CCRMCompanyAddress from "@/components/Customer/Crm/Company/_address/CCRMCompanyAddress";
import CCRMCompanyContactPersons from "@/components/Customer/Crm/Company/_contact_person/CCRMCompanyContactPersons";
import CCRMCustomerAccessDataList from "../_customer_access_data/CCRMCustomerAccessDataList";
import CCRMCompanyFaq from "../_faq/CCRMCompanyFaq";
import CCRMConversation from "@/components/Customer/Crm/Company/_conversation/CCRMConversation";
import NoticeList from "../../../../../modules/notice/components/NoticeList";
export default {
  name: "CCRMCompany",
  components: {
    CCRMCompanyEdit,
    CCRMCompanyAddress,
    CCRMCompanyContactPersons,
    CCRMCustomerAccessDataList,
    CCRMCompanyFaq,
    CCRMConversation,
    NoticeList
  },
  props: {
    company: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      intern_company: this.company,
      customer_id: null,
      company_delete_dialog: false,
      selected_company: {},
      notices: [],
      top_menu: {
        index: 0,
        tabs: [
          {slug: 'company', name: 'Firma'},
          {slug: 'address', name: 'Standorte'},
          {slug: 'contact_person', name: 'Ansprechpartner'},
          {slug: 'access_data', name: 'Zugangsdaten'},
          {slug: 'faq', name: 'FAQ'},
          {slug: 'conversation', name: this.$t('customer.crm.company.conversation.title_menu')},
          {slug: 'notice', name: this.$t('notice.title')},
        ],
      },
    }
  },
  created() {
    this.$store.dispatch('noticesFromCustomer',{customer_id: this.company.customer_id}).then(() => {
      this.notices = this.$store.getters.notices
    })
  },
  computed: {
    changeVertical() {
      return window.innerWidth >= 960
    }
  },
  methods: {
    companyDeleteDialog(company) {
      this.selected_company = company
      this.company_delete_dialog = true
    },
    companyDelete() {
      this.$store.dispatch('companyDelete', this.selected_company.id).then(() => {
        this.$emit('companyDelete')
        this.company_delete_dialog = false
      })
    }
  }
}
</script>

<style lang="scss">

.mx-tab_active {
  background: #f2f2f2;
  //color: #333 !important;
}

</style>
