<template>
  <v-dialog v-model="dialog" persistent style="z-index: 999999;" :max-width="getWidth">
    <v-card elevation="0" class="pt-4 pb-8 px-2">
      <v-card-title class="headline mb-9">
        <div>
          {{ $t('customer.crm.company.person.title') }}
        </div>
        <div style="font-size: 16px; display: block; width: 100%; color: #494949" >
          {{ $t('customer.crm.company.person.csv.prompt.subtitle') }}
        </div>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-file-input
                v-model="file"
                :label="$t('customer.crm.company.person.csv.label')"
                :placeholder="$t('customer.crm.company.person.csv.button_export')"
                prepend-icon=""
                prepend-inner-icon="mdi-paperclip"
                outlined
                :show-size="1000"
                :error="errors.file!==undefined"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip v-if="index < 4" color="primary" dark label small>
                  {{ text }}
                </v-chip>

                <span v-else-if="index === 4" class="text-overline grey--text text--darken-3 mx-2">
                  +{{ form.file.length - 4 }} File(s)
                </span>
              </template>
            </v-file-input>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between mr-2">
        <div>
          <v-btn :loading="btn_disable" :disabled="btn_disable" class="mr-2" color="green white--text" @click="companyContactPersonCreate" elevation="1" large raised tile>
            {{ $t('customer.buttons.save') }}
          </v-btn>
          <v-btn color="grey white--text" @click="dialogClose" elevation="1" large raised tile>
            {{ $t('customer.buttons.abort') }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "CCRMCompanyFaqQuestionCSVCreate",
  props: {
    open: {
      type: Boolean
    },
    faq: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      btn_disable: false,
      file: null,
      errors: []
    }
  },
  watch: {
    open() {
      this.dialog = true
    },
  },
  computed: {
    getWidth() {
      return window.innerWidth <= 1200 ? '90%' : '1100px'
    }
  },
  methods: {
    dialogClose() {
      this.dialog = false
      this.btn_disable = false
      this.file = null
    },
    companyContactPersonCreate() {
      this.btn_disable = true

      let formData = new FormData()
      formData.append('id', this.faq.id)
      formData.append('file', this.file)

      axios.post(`customer/companies/faq/questions/question/csv/import/${this.faq.id}`, formData, {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        this.$emit('result', response)
        this.dialogClose()
      }).catch(error => {
        this.btn_disable = false
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;
        }
      })
    }
  }
}
</script>
