<template>
  <v-dialog v-model="dialog" persistent style="z-index: 999999;" :max-width="getWidth">
    <v-card class="pt-4 pb-10 px-2">
      <v-card-title class="headline mb-9">
        {{ $t('customer.crm.company.new_company') }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
                v-model="form.company_name"
                outlined
                hide-details
                type="email"
                :label="$t('customer.crm.company.company_name')"
                :placeholder="$t('customer.crm.company.company_name')"
                required
                :error="errors.company_name!==undefined"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
                v-model="form.company_name_short"
                outlined
                hide-details
                type="email"
                :label="$t('customer.crm.company.company_name_short')"
                :placeholder="$t('customer.crm.company.company_name_short')"
                required
                :error="errors.company_name_short!==undefined"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
                v-model="form.company_phone"
                outlined
                hide-details
                type="email"
                :label="$t('customer.crm.company.company_phone')"
                :placeholder="$t('customer.crm.company.company_phone')"
                required
                :error="errors.company_phone!==undefined"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider class="my-8"></v-divider>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
                v-model="form.country"
                outlined
                hide-details
                type="email"
                :label="$t('customer.crm.company.country')"
                :placeholder="$t('customer.crm.company.country')"
                required
                :error="errors.country!==undefined"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
                v-model="form.postcode"
                outlined
                hide-details
                type="email"
                :label="$t('customer.crm.company.postcode')"
                :placeholder="$t('customer.crm.company.postcode')"
                required
                :error="errors.postcode!==undefined"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
                v-model="form.city"
                outlined
                hide-details
                type="email"
                :label="$t('customer.crm.company.city')"
                :placeholder="$t('customer.crm.company.city')"
                required
                :error="errors.city!==undefined"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
                v-model="form.street"
                outlined
                hide-details
                type="email"
                :label="$t('customer.crm.company.street')"
                :placeholder="$t('customer.crm.company.street')"
                required
                :error="errors.street!==undefined"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end mr-2">
        <v-btn :loading="btn_disable" :disabled="btn_disable" color="green white--text"  @click="companyCreate" elevation="1" large raised tile>
          {{ $t('customer.buttons.save') }}
        </v-btn>
        <v-btn color="grey" dark @click="dialogClose" elevation="1" large raised tile>
          {{ $t('customer.buttons.abort') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CCRMCompanyCreate",
  props: {
    open: {
      type: Boolean
    }
  },
  data() {
    return {
      dialog: false,
      btn_disable: false,
      form: {
        company_name: '',
        company_name_short: '',
        company_phone: '',
        customer_id: null,
        is_main: 1,
        opening_hours: {
          mo: {from: 'null', to: 'null', open: '1'},
          di: {from: 'null', to: 'null', open: '1'},
          mi: {from: 'null', to: 'null', open: '1'},
          do: {from: 'null', to: 'null', open: '1'},
          fr: {from: 'null', to: 'null', open: '1'},
          sa: {from: 'null', to: 'null', open: '1'},
          so: {from: 'null', to: 'null', open: '1'}
        }
      },
      errors: {}
    }
  },
  watch: {
    open() {
      this.dialog = true
    },
  },
  computed: {
    getWidth() {
      return window.innerWidth <= 1200 ? '90%' : '1100px'
    }
  },
  methods: {
    dialogClose() {
      this.dialog = false
      this.btn_disable = false
      this.form = this.getForm()
    },
    getForm() {
      return {
        company_name: '',
        company_name_short: '',
        company_phone: '',
        customer_id: null,
        is_main: 1,
        opening_hours: {
          mo: {from: 'null', to: 'null', open: '1'},
          di: {from: 'null', to: 'null', open: '1'},
          mi: {from: 'null', to: 'null', open: '1'},
          do: {from: 'null', to: 'null', open: '1'},
          fr: {from: 'null', to: 'null', open: '1'},
          sa: {from: 'null', to: 'null', open: '1'},
          so: {from: 'null', to: 'null', open: '1'}
        }
        }
    },
    companyCreate() {
      this.btn_disable = true
      this.form.customer_id = this.$store.getters.customerCrm.customer.id
      this.$store.dispatch('companyCreate', this.form).then(() => {
        this.$emit('companyAdd')
        this.dialogClose()
      }).catch(error => {
        this.btn_disable = false
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;
        }
      })
    }
  }
}
</script>
