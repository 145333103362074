<template>
  <v-card elevation="0" class="pb-10 px-2">
    <v-row class="mb-0">
      <v-col cols="12" class="d-flex align-center">
        <v-text-field
            class="mt-2"
            v-model="search"
            outlined
            hide-details
            :label="$t('customer.search.label')"
            :placeholder="$t('customer.search.placeholder')"
            required
        ></v-text-field>
        <v-btn height="58" x-large class="mt-2 ml-3" color="primary white--text" elevation="0"
               @click="contact_person_create_dialog = !contact_person_create_dialog">
          <v-icon size="36" color="white--text">mdi-plus</v-icon>
        </v-btn>
        <v-btn height="58" x-large class="mt-2 ml-3" color="primary white--text" elevation="0"
               @click="contact_person_csv_dialog = !contact_person_csv_dialog">
          CSV
        </v-btn>
      </v-col>
    </v-row>
    <v-row :style="`height: ${height}px; overflow-x: auto`">
      <v-col cols="12">
        <v-data-table :headers="headers" :items="array" :search="search" :items-per-page="10">
<!--          <template v-slot:item.is_main="{ item }">-->
<!--            <v-chip v-if="item.is_main" label x-small color="primary" outlined style="padding-bottom: 1px;">-->
<!--              {{ $t(`customer.crm.company.main_address`) }}-->
<!--            </v-chip>-->
<!--          </template>-->
          <template v-slot:item.gender="{ item }">
            {{ $t(`customer.gender.official.${item.gender}`) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn text elevation="0" small @click="contactPersonEdit(item)">
              <v-icon size="16" color="blue-grey darken-4">mdi-pencil-outline</v-icon>
            </v-btn>
            <v-btn v-if="item.is_main === 0 || !item.is_main" text elevation="0" small
                   @click="contactPersonDeleteDialog(item)">
              <v-icon size="16" color="blue-grey darken-4">mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <ContactPersonCreate @result="contactPersonCreateResult" :company="company" :open="contact_person_create_dialog"/>
    <ContactPersonCSVCreate @result="contactPersonCreateCSVResult" :company="company" :open="contact_person_csv_dialog"/>
    <ContactPersonEdit @result="contactPersonEditResult" :contact_person="selected_contact_person" :open="contact_person_edit_dialog"/>

    <v-row justify="center">
      <v-dialog v-model="contact_person_delete_dialog" persistent max-width="500">
        <v-card class="pa-4">
          <v-card-title class="headline mb-9">
            <h3 style="text-align: center">
              {{ $t('customer.crm.company.delete_contact_person') }}
            </h3>
          </v-card-title>
          <v-card-actions class="d-flex justify-center">
            <v-btn :loading="delete_btn_disabled" :disabled="delete_btn_disabled" color="red" style="color: #ffffff" @click="contactPersonDelete">
              {{ $t('customer.buttons.delete') }}
            </v-btn>
            <v-btn color="grey" dark @click="contact_person_delete_dialog = false">
              {{ $t('customer.buttons.abort') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-card>
</template>

<script>
import ContactPersonCreate from "@/components/Customer/Crm/Company/_contact_person/CCRMCompanyContactPersonCreate";
import ContactPersonEdit from "@/components/Customer/Crm/Company/_contact_person/CCRMCompanyContactPersonEdit";
import ContactPersonCSVCreate
  from "@/components/Customer/Crm/Company/_contact_person/CCRMCompanyContactPersonCSVCreate";

export default {
  name: "CCRMCompanyContactPersons",
  components: {ContactPersonCreate, ContactPersonEdit, ContactPersonCSVCreate},
  props: {
    company: {
      type: Object,
      required: true
    },
    contact_persons: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      search: '',
      height: '',
      delete_btn_disabled: false,
      contact_person_delete_dialog: false,
      contact_person_edit_dialog: false,
      contact_person_create_dialog: false,
      contact_person_csv_dialog: false,
      headers: [
        // { text: this.$t(`customer.crm.company.person.table.id`), value: 'id' },
        {text: this.$t(`customer.crm.company.address_short_name`), value: 'address_name'},
        {text: this.$t(`customer.crm.company.person.table.gender`), value: 'gender'},
        {text: this.$t(`customer.crm.company.person.table.first_name`), value: 'first_name'},
        {text: this.$t(`customer.crm.company.person.table.last_name`), value: 'last_name'},
        {text: this.$t(`customer.crm.company.person.table.position`), value: 'position'},
        {text: this.$t(`customer.crm.company.person.table.phone`), value: 'phone'},
        {text: this.$t(`customer.crm.company.person.table.email`), value: 'email'},
        // {text: this.$t(`customer.crm.company.person.table.identification`), value: 'identification'},
        {text: '', value: 'actions', sortable: false,},
      ],
      array: [...this.contact_persons],
      selected_contact_person: {},
    }
  },
  created() {
    this.getArray()
    // this.array = this.gsm(this.company.addresses, ...this.contact_persons)

  },
  methods: {
    getArray() {
      let addresses = this.company.addresses
      let _array = [...this.contact_persons]

      _array.map(person => {
        return addresses.map(address => {
          if (person.address_id === address.id) {
            person['address_name'] = address.address_short_name
          }
          return person
        })
      })

      this.array = _array
    },

    gsm(addresses, contact_persons) {
      return contact_persons.map(person => {
        return addresses.map(address => {
          if (person.address_id === address.id) {
            person['address_name'] = address.address_short_name
          }
          return person
        })
      })
    },
    contactPersonEdit(item) {
      this.selected_contact_person = item
      this.contact_person_edit_dialog = !this.contact_person_edit_dialog
    },
    contactPersonDeleteDialog(item) {
      this.selected_contact_person = item
      this.contact_person_delete_dialog = !this.contact_person_delete_dialog
    },
    contactPersonDelete() {
      this.delete_btn_disabled = true
      this.$store.dispatch('companyContactPersonDelete', this.selected_contact_person.id).then(() => {
        let index = this.array.findIndex(item => item.id === this.selected_contact_person.id)
        this.array.splice(index, 1)
        this.contact_person_delete_dialog = false
        this.delete_btn_disabled = false
      })
    },
    contactPersonCreateCSVResult(e) {
      this.array = e.data.data
    },
    contactPersonCreateResult() {
      const contact_person = this.$store.getters.company_contact_person
      this.company.addresses.map(addresses => {
        if (addresses.id === parseInt(contact_person.address_id)) {
          contact_person['address_name'] = addresses.address_short_name
        }
      })
      this.array.push(contact_person)
    },
    contactPersonEditResult(contact_person) {
      this.company.addresses.map(addresses => {
        if (addresses.id === parseInt(contact_person.address_id)) {
          contact_person['address_name'] = addresses.address_short_name
        }
      })

      let index = this.array.findIndex(item => item.id === contact_person.id)
      this.$set(this.array, index, contact_person)
    }
  }
}
</script>
