<template>
  <v-dialog v-model="dialog" persistent style="z-index: 999999;" :max-width="getWidth">
    <div v-show="complete_created === 'not created'">
      <v-card elevation="0" class="pt-4 pb-8 px-2">
        <v-card-title class="headline mb-9">
          <div>
            {{ $t('customer.crm.company.person.title') }}
          </div>
          <div style="font-size: 16px; display: block; width: 100%; color: #494949">
            {{ $t('customer.crm.company.person.csv.prompt.subtitle') }}
          </div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">

              <div class="errors">

                <v-alert
                    v-if="errors.max_length"
                    dense
                    outlined
                    type="error"

                >
                  {{ $t('customer.crm.company.person.csv.prompt.max_length_or_d') }}
                </v-alert>

                <v-alert
                    v-if="errors.fileExtension !== undefined"
                    dense
                    outlined
                    type="error"
                >
                  {{ $t('customer.crm.company.person.csv.prompt.file_extension') }}
                </v-alert>

                <v-alert
                    v-if="errors.file !== undefined"
                    dense
                    outlined
                    type="error"
                >
                  {{ $t('customer.crm.company.person.csv.prompt.file_not_exist') }}
                </v-alert>

              </div>


              <v-file-input
                  v-model="file"
                  :label="$t('customer.crm.company.person.csv.label')"
                  :placeholder="$t('customer.crm.company.person.csv.button_export')"
                  prepend-icon=""
                  prepend-inner-icon="mdi-paperclip"
                  outlined
                  :show-size="1000"
                  :error="errors.file !== undefined || errors.fileExtension !== undefined"
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip v-if="index < 4" color="primary" dark label small>
                    {{ text }}
                  </v-chip>

                  <span v-else-if="index === 4" class="text-overline grey--text text--darken-3 mx-2">
                +{{ form.file.length - 4 }} File(s)
              </span>
                </template>
              </v-file-input>
            </v-col>
            <v-col cols="12">
              <v-progress-linear
                  v-if="progress.status"
                  v-model="progress.value"
                  :buffer-value="0"
                  stream
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between mr-2">
          <div>
            <v-checkbox
                class="ml-2"
                v-model="delete_all_contact_person"
                :label="$t('customer.crm.company.person.delete_all_contact_person')"
                :false-value="0"
                :true-value="1"
            ></v-checkbox>
            <div class="d-flex" v-if="delete_all_contact_person">
              <v-alert
                  dense
                  outlined
                  type="error"
                  v-html="$t('customer.crm.company.person.csv.prompt.warning')"
              ></v-alert>
              <v-btn :disabled="!btn_disabled_contact_delete" @click="btn_disabled_contact_delete = false" class="ml-2" color="error" style="padding: 21px 10px;" tile>
                {{ $t('customer.crm.company.person.csv.prompt.warning_btn') }}
              </v-btn>
            </div>
            <!--          <div v-if="delete_all_contact_person" style="font-size: 16px; display: block; margin-left: 10px; width: 100%; color: #9d0909" >-->
            <!--            {{ $t('customer.crm.company.person.csv.prompt.warning') }}-->
            <!--          </div>-->
          </div>
          <div>
            <v-btn :disabled="btn_disable || btn_disabled_contact_delete" class="mr-2" color="green white--text" @click="companyContactPersonCreate" elevation="1" large raised tile>
              {{ $t('customer.buttons.save') }}
            </v-btn>
            <v-btn :disabled="btn_disable" color="grey white--text" @click="dialogClose" elevation="1" large raised tile>
              {{ $t('customer.buttons.abort') }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </div>

    <div v-show="complete_created === 'created'">
      <v-card elevation="0" class="pt-4 pb-8 px-2 d-flex justify-center align-center" style="min-height: 300px">
        <div>
          <h2 class="mt-16">{{ $t('customer.crm.company.person.csv.prompt.complete_title') }}</h2>
          <div class="animation-ctn">
            <div class="icon icon--order-success svg">
              <svg xmlns="http://www.w3.org/2000/svg" width="154px" height="154px">
                <g fill="none" stroke="#4caf50" stroke-width="2">
                  <circle cx="77" cy="77" r="72" style="stroke-dasharray:480px, 480px; stroke-dashoffset: 960px;"></circle>
                  <circle id="colored" fill="#22AE73" cx="77" cy="77" r="72" style="stroke-dasharray:480px, 480px; stroke-dashoffset: 960px;"></circle>
                  <polyline class="st0" stroke="#fff" stroke-width="10" points="43.5,77.8 63.7,97.9 112.2,49.4 " style="stroke-dasharray:100px, 100px; stroke-dashoffset: 200px;"/>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "CCRMCompanyAddressCSVCreate",
  props: {
    open: {
      type: Boolean
    },
    company: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      complete_created: 'not created',
      btn_disabled_contact_delete: false,
      btn_disable: false,
      delete_all_contact_person: false,
      file: null,
      progress: {
        status: false,
        value: 0,
        bufferValue: 100,
        interval: 0,
      },
      errors: []
    }
  },
  watch: {
    open() {
      this.complete_created = 'not created'
      this.dialog = true
    },
    'progress.value'(val) {
      if (val < 100) return

      this.progress.value = 0
      this.startBuffer()
    },
    delete_all_contact_person() {
      if (this.delete_all_contact_person) {
        this.btn_disabled_contact_delete = true
      } else {
        this.btn_disabled_contact_delete = false
      }
    }
  },
  computed: {
    getWidth() {
      return window.innerWidth <= 1200 ? '90%' : '1240px'
    }
  },
  methods: {
    startBuffer () {
      clearInterval(this.progress.interval )
      this.progress.interval = setInterval(() => {
        this.progress.value += Math.random() * (15 - 5) + 5
      }, 600)

    },
    dialogClose() {
      this.delete_all_contact_person = 0
      this.dialog = false
      this.errors = []
      this.file = null
    },
    etFileExtension(filename) {
      return filename.split('.').pop() !== 'csv';
    },
    companyContactPersonCreate() {
      if (this.file === null) {
        this.errors = {'file': 'null'}
        return true
      }

      if (this.etFileExtension(this.file.name)) {
        this.errors = {'fileExtension': 'file_extension'}
        return true
      }

      this.btn_disable = true

      let formData = new FormData()
      formData.append('id', this.company.id)
      formData.append('delete_all_contact_person', this.delete_all_contact_person)
      formData.append('file', this.file)
      this.progress.status = true
      this.startBuffer()

      axios.post(`customer/companies/contact-persons/${this.company.id}/csv/import`, formData, {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        this.$emit('result', response)
        this.complete_created = 'created'

        this.file = null
        this.btn_disable = false
        this.delete_all_contact_person = 0
        clearInterval(this.progress.interval)
        this.progress.status = false
        this.progress.value = 0

        setTimeout(() => {
          this.dialogClose()
        }, 3500)

      }).catch(error => {
        this.btn_disable = false
        clearInterval(this.progress.interval)
        this.progress.status = false
        this.progress.value = 0
        switch (error.response.status) {
          case 400:
            this.errors = error.response.data.error;
            break;
          case 422:
            this.errors = error.response.data.errors;
            break;
          case 500:
            this.errors = {'max_length': true};
            break;
        }
      })

    }
  }
}
</script>

<style lang="scss">
.max_length {
  color: #941a1a !important;
  //color: #1a6e49;
  text-decoration: underline;
}

.errors {
  margin-bottom: 5px;
  span {
    color: #941a1a !important;
  }
}


.animation-ctn{
  text-align:center;
  margin-top:5em;
  margin-bottom:5em;
}

@-webkit-keyframes checkmark {
  0% {
    stroke-dashoffset: 100px
  }

  100% {
    stroke-dashoffset: 200px
  }
}

@-ms-keyframes checkmark {
  0% {
    stroke-dashoffset: 100px
  }

  100% {
    stroke-dashoffset: 200px
  }
}

@keyframes checkmark {
  0% {
    stroke-dashoffset: 100px
  }

  100% {
    stroke-dashoffset: 0px
  }
}

@-webkit-keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 480px

  }

  100% {
    stroke-dashoffset: 960px;

  }
}

@-ms-keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 240px
  }

  100% {
    stroke-dashoffset: 480px
  }
}

@keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 480px
  }

  100% {
    stroke-dashoffset: 960px
  }
}

@keyframes colored-circle {
  0% {
    opacity:0
  }

  100% {
    opacity:100
  }
}

/* other styles */
/* .svg svg {
    display: none
}
 */
.inlinesvg .svg svg {
  display: inline
}

/* .svg img {
    display: none
} */

.icon--order-success svg polyline {
  -webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
  animation: checkmark 0.25s ease-in-out 0.7s backwards
}

.icon--order-success svg circle {
  -webkit-animation: checkmark-circle 0.6s ease-in-out backwards;
  animation: checkmark-circle 0.6s ease-in-out backwards;
}
.icon--order-success svg circle#colored {
  -webkit-animation: colored-circle 0.6s ease-in-out 0.7s backwards;
  animation: colored-circle 0.6s ease-in-out 0.7s backwards;
}
</style>
