<template>
  <v-dialog v-model="dialog" persistent style="z-index: 999999;" :max-width="getWidth">
    <v-card elevation="0" class="pt-4 pb-8 px-2">
      <v-card-title class="headline mb-9">
        {{ $t('customer.crm.company.person.title') }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-select v-model="form.address_id"
                      :label="$t('company.person.short_name')"
                      :items="addresses"
                      item-value="id"
                      item-text="address_short_name"
                      outlined
                      hide-details
                      :append-icon="'mdi-chevron-down'"
                      :menu-props="{ bottom: true, offsetY: true }"
                      :error="errors.address_id!==undefined"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-select v-model="form.gender"
                      :label="$t('customer.gender.title')"
                      :items="gender_select"
                      outlined
                      hide-details
                      :append-icon="'mdi-chevron-down'"
                      :menu-props="{ bottom: true, offsetY: true }"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
                v-model="form.first_name"
                outlined
                hide-details
                :label="$t('customer.crm.company.person.form.first_name')"
                :placeholder="$t('customer.crm.company.person.form.first_name')"
                required
                :error="errors.first_name!==undefined"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
                v-model="form.last_name"
                outlined
                hide-details
                :label="$t('customer.crm.company.person.form.last_name')"
                :placeholder="$t('customer.crm.company.person.form.last_name')"
                required
                :error="errors.last_name!==undefined"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider class="my-6"></v-divider>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
                v-model="form.position"
                outlined
                hide-details
                :label="$t('customer.crm.company.person.form.position')"
                :placeholder="$t('customer.crm.company.person.form.position')"
                required
                :error="errors.position!==undefined"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
                v-model="form.phone"
                outlined
                hide-details
                :label="$t('customer.crm.company.person.form.phone')"
                :placeholder="$t('customer.crm.company.person.form.phone')"
                required
                :error="errors.phone!==undefined"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
                v-model="form.email"
                outlined
                hide-details
                type="email"
                :label="$t('customer.crm.company.person.form.email')"
                :placeholder="$t('customer.crm.company.person.form.email')"
                required
                :error="errors.email!==undefined"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between mr-2">
        <v-checkbox
            class="ml-2"
            v-model="form.put_through"
            :label="$t('customer.crm.company.person.form.put_through')"
            :false-value="0"
            :true-value="1"
        ></v-checkbox>
        <div>
          <v-btn :loading="btn_disable" :disabled="btn_disable" class="mr-2" color="green white--text" @click="companyContactPersonCreate" elevation="1" large raised tile>
            {{ $t('customer.buttons.save') }}
          </v-btn>
          <v-btn color="grey white--text" @click="dialogClose" elevation="1" large raised tile>
            {{ $t('customer.buttons.abort') }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CCRMCompanyAddressEdit",
  props: {
    open: {
      type: Boolean
    },
    contact_person: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      btn_disable: false,
      form: {},
      gender_select: [
        {
          text: this.$t('customer.gender.female'),
          value: 'female'
        },
        {
          text: this.$t('customer.gender.male'),
          value: 'male'
        },
        {
          text: this.$t('customer.gender.divers'),
          value: 'divers'
        },
        {
          text: this.$t('customer.gender.indefinite'),
          value: 'indefinite'
        },
      ],
      addresses: [],
      errors: []
    }
  },
  watch: {
    open() {
      this.form = {...this.contact_person}
      this.getAddresses()
      this.dialog = true
    },
  },
  computed: {
    getWidth() {
      return window.innerWidth <= 1200 ? '90%' : '1100px'
    }
  },
  methods: {
    getAddresses() {
      this.addresses = this.$store.getters.companies.find(c => c.id === this.form.company_id).addresses
    },
    dialogClose() {
      this.dialog = false
      this.btn_disable = false
      this.form = {}
    },
    companyContactPersonCreate() {
      this.btn_disable = true
      this.$store.dispatch('companyContactPersonUpdate', this.form).then(() => {
        this.$emit('result', this.form)
        this.dialogClose()
      }).catch(error => {
        this.btn_disable = false
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;
        }
      })
    }
  }
}
</script>
