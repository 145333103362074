<template>
  <v-card elevation="0" class="pb-10 px-2">
    <v-card-title class="headline mb-9">
      {{ $t('customer.crm.company.company') }}
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col class="mb-3" cols="12" md="8">
          <v-text-field
              v-model="form.company_name"
              outlined
              hide-details
              type="email"
              :label="$t('customer.crm.company.company_name')"
              :placeholder="$t('customer.crm.company.company_name')"
              required
              :error="errors.company_name!==undefined"
              @input="watch"
              :readonly="form_disabled"
          ></v-text-field>
        </v-col>
        <v-col class="mb-3" cols="12" md="8">
          <v-text-field
              v-model="form.company_name_short"
              outlined
              hide-details
              type="email"
              :label="$t('customer.crm.company.company_name_short')"
              :placeholder="$t('customer.crm.company.company_name_short')"
              required
              :error="errors.company_name_short!==undefined"
              @input="watch"
              :readonly="form_disabled"
          ></v-text-field>
        </v-col>
        <v-col class="mb-3" cols="12" md="8">
          <v-text-field
              v-model="form.company_phone"
              outlined
              hide-details
              type="email"
              :label="$t('customer.crm.company.company_phone')"
              :placeholder="$t('customer.crm.company.company_phone')"
              required
              :error="errors.company_phone!==undefined"
              @input="watch"
              :readonly="form_disabled"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="ml-2">
      <v-btn v-if="!form_disabled" :loading="btn_disable"  color="green" @click="companyUpdate" elevation="1" large raised tile :disabled="button_disabled">
        {{ $t('customer.buttons.save') }}
      </v-btn>
      <v-btn :hidden="!$store.getters.customerCrmEditDisabled" color="grey" dark @click="abort" elevation="1" large raised tile>
        {{ $t('customer.buttons.abort') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import _ from 'lodash';

export default {
  name: "CCRMCompanyEdit",
  props: {
    company: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      button_disabled: true,
      btn_disable: false,
      form: {
        company_name: '',
        company_name_short: '',
        company_phone: '',
        customer_id: null,
      },
      form_disabled: true,
      errors: {}
    }
  },
  created() {
    this.canCompanyEdit()
    this.form = {...this.company}
  },
  methods: {
    watch() {
      this.disabled()
    },
    canCompanyEdit() {
      if (this.mxware.can('customer-company-update')) {
        this.form_disabled = false
      }
    },
    disabled() {
      this.button_disabled = _.isEqual(this.company, this.form)
      this.$store.commit('SET_CUSTOMER_CRM_EDIT_DISABLED', !this.button_disabled)
    },
    abort() {
      this.form = {...this.company}
      this.disabled()
    },
    companyUpdate() {
      this.btn_disable = true
      this.button_disabled = true
      this.form.address_id = null
      this.form.customer_id = this.$store.getters.customerCrm.customer.id
      this.$store.dispatch('companyUpdate', this.form).then(() => {
        this.button_disabled = true
        this.$store.commit('SET_CUSTOMER_CRM_EDIT_DISABLED', !this.button_disabled)
        this.btn_disable = false
      })
    }
  }
}
</script>
