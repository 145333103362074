<template>
  <v-card elevation="0" class="pb-10 px-2">
    <v-row class="mb-0">
      <v-col cols="12" class="d-flex align-center">
        <v-text-field
            class="mt-2"
            v-model="search"
            outlined
            hide-details
            :label="$t('customer.search.label')"
            :placeholder="$t('customer.search.placeholder')"
            required
        ></v-text-field>
        <v-btn height="58" x-large class="mt-2 ml-3" color="primary white--text" elevation="0" @click="create_dialog = !create_dialog">
          <v-icon size="36" color="white--text">mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row :style="`height: ${height}px; overflow-x: auto`">
      <v-col cols="12">
        <v-data-table :headers="headers" :items="array" :search="search" :items-per-page="10">
          <template v-slot:item.url="{ item }">
            <a :href="item.url" target="_blank">{{item.url}}</a>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn text elevation="0" small @click="edit(item)">
              <v-icon size="16" color="blue-grey darken-4">mdi-pencil-outline</v-icon>
            </v-btn>
            <v-btn text elevation="0" small @click="deleteDialog(item)">
              <v-icon size="16" color="blue-grey darken-4">mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <CCRMCustomerAccessDataCreate :company="company" :open="create_dialog" @result="createResult"/>
    <CCRMCustomerAccessDataEdit :data_props="selected_item" :company="company" :open="edit_dialog" @result="editResult"/>

    <v-row justify="center">
      <v-dialog v-model="delete_dialog" persistent max-width="500">
        <v-card class="pa-4">
          <v-card-title class="headline mb-9">
            <h3 style="text-align: center">
              {{ $t('customer.crm.company.cad.delete_title') }}
            </h3>
          </v-card-title>
          <v-card-actions class="d-flex justify-center">
            <v-btn color="red" dark @click="itemDelete">
              {{ $t('customer.buttons.delete') }}
            </v-btn>
            <v-btn color="grey" dark @click="delete_dialog = false">
              {{ $t('customer.buttons.abort') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-card>
</template>

<script>

import CCRMCustomerAccessDataCreate from "./CCRMCustomerAccessDataCreate";
import CCRMCustomerAccessDataEdit from "./CCRMCustomerAccessDataEdit";

export default {
  name: "CCRMCustomerAccessDataList",
  components: {CCRMCustomerAccessDataCreate, CCRMCustomerAccessDataEdit},
  props: {
    company: {
      type: Object,
      required: true
    },
    data_list: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      search: '',
      height: '',
      create_dialog: false,
      edit_dialog: false,
      delete_dialog: false,
      headers: [
        { text: this.$t(`customer.crm.company.cad.title`), value: 'title' },
        { text: this.$t(`customer.crm.company.cad.description`), value: 'description' },
        { text: this.$t(`customer.crm.company.cad.url`), value: 'url'},
        { text: this.$t(`customer.crm.company.cad.login`), value: 'login'},
        { text: this.$t(`customer.crm.company.cad.password`), value: 'password'},
        { text: this.$t(`customer.crm.company.cad.is_active`), value: 'is_active'},
        { text: '', value: 'actions', sortable: false, },
      ],
      array: [...this.data_list],
      selected_item: {},
    }
  },
  methods: {
    edit(item) {
      this.selected_item = item
      this.edit_dialog = !this.edit_dialog
    },
    deleteDialog(item) {
      this.selected_item = item
      this.delete_dialog = !this.delete_dialog
    },
    itemDelete() {
      this.$store.dispatch('companyCustomerAccessDataDelete', this.selected_item.id).then(() => {
        let index = this.array.findIndex(item => item.id === this.selected_item.id)
        this.array.splice(index, 1)
        this.delete_dialog = false
      })
    },
    createResult() {
      this.array.push(this.$store.getters.company_customer_access_data)
    },
    editResult(result) {
      let index = this.array.findIndex(item => item.id === result.id)
      this.$set(this.array, index, result)
    }
  }
}
</script>
