<template>
  <v-card elevation="0" class="pb-10 px-2">
    <v-row class="mb-0">
      <v-col cols="12" class="d-flex align-center">
        <v-text-field
            class="mt-2"
            v-model="search"
            outlined
            hide-details
            :label="$t('customer.search.label')"
            :placeholder="$t('customer.search.placeholder')"
            required
        ></v-text-field>
        <v-btn height="58" x-large class="mt-2 ml-3" color="primary white--text" elevation="0" @click="address_create_dialog = !address_create_dialog">
          <v-icon size="36" color="white--text">mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row :style="`height: ${height}px; overflow-x: auto`">
      <v-col cols="12">
        <v-data-table :headers="headers" :items="addresses_array" :search="search" :items-per-page="10">
          <template v-slot:item.is_main="{ item }">
            <v-chip v-if="isMain(item.is_main)" label x-small color="primary" outlined style="padding-bottom: 1px;">
              {{ $t(`customer.crm.company.main_address`) }}
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn text elevation="0" small @click="addressEdit(item)">
              <v-icon size="16" color="blue-grey darken-4">mdi-pencil-outline</v-icon>
            </v-btn>
            <v-btn v-if="item.is_main === 0 || !item.is_main" text elevation="0" small @click="addressDeleteDialog(item)">
              <v-icon size="16" color="blue-grey darken-4">mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <CCRMCompanyAddressCreate @result="addressCreateResult" :company_id="company_id" :open="address_create_dialog"/>
    <CCRMCompanyAddressEdit @result="addressEditResult" :open="address_edit_dialog" :address="selected_address"/>

    <v-row justify="center">
      <v-dialog v-model="address_delete_dialog" persistent max-width="500">
        <v-card class="pa-4">
          <v-card-title class="headline mb-9">
            <h3 style="text-align: center">
              {{ $t('customer.crm.company.delete_location') }}
            </h3>
          </v-card-title>
          <v-card-actions class="d-flex justify-center">
            <v-btn color="red" dark @click="addressDelete">
              {{ $t('customer.buttons.delete') }}
            </v-btn>
            <v-btn color="grey" dark @click="address_delete_dialog = false">
              {{ $t('customer.buttons.abort') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-card>
</template>

<script>
import CCRMCompanyAddressEdit from "@/components/Customer/Crm/Company/_address/CCRMCompanyAddressEdit";
import CCRMCompanyAddressCreate from "@/components/Customer/Crm/Company/_address/CCRMCompanyAddressCreate";

export default {
  name: "CCRMCompanyAddress",
  components: {CCRMCompanyAddressEdit, CCRMCompanyAddressCreate},
  props: {
    company_id: {
      required: true
    },
    addresses: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      search: '',
      height: '',
      address_delete_dialog: false,
      address_edit_dialog: false,
      address_create_dialog: false,
      headers: [
        { text: this.$t(`customer.crm.company.id`), value: 'id' },
        { text: this.$t(`customer.crm.company.address_short_names`), value: 'address_short_name' },
        { text: this.$t(`customer.crm.company.city`), value: 'city' },
        { text: this.$t(`customer.crm.company.postcode`), value: 'postcode' },
        { text: this.$t(`customer.crm.company.street`), value: 'street' },
        { text: this.$t(`customer.crm.company.country`), value: 'country' },
        { text: this.$t(`customer.crm.company.main_address`), value: 'is_main', sortable: false, },
        { text: '', value: 'actions', sortable: false, },
      ],
      addresses_array: [...this.addresses],
      selected_address: {},
    }
  },
  methods: {
    isMain(is_main) {
      return parseInt(is_main) === 1
    },
    addressEdit(item) {
      this.selected_address = item
      this.address_edit_dialog = !this.address_edit_dialog
    },
    addressDeleteDialog(item) {
      this.selected_address = item
      this.address_delete_dialog = !this.address_delete_dialog
    },
    addressDelete() {
      this.$store.dispatch('companyAddressDelete', this.selected_address.id).then(() => {
        let index = this.addresses_array.findIndex(address => address.id === this.selected_address.id)
        this.addresses_array.splice(index, 1)
        this.address_delete_dialog = false
      })
    },
    addressCreateResult() {
      let result = this.$store.getters.company_address

      if(1 === parseInt(result.is_main) && this.addresses_array.length > 1) {
        this.addresses_array = this.addresses_array.map(address => {
          address.is_main = 0
          return address
        })
      }
      else {
        result.is_main = 0
      }
      this.addresses_array.push(result)
    },
    addressEditResult(result) {
      if(1 === parseInt(result.is_main) && this.addresses_array.length > 1) {
        this.addresses_array = this.addresses_array.map(address => {
          address.is_main = 0
          return address
        })
      }

      let index = this.addresses_array.findIndex(address => address.id === result.id)
      this.$set(this.addresses_array, index, result)
    }
  }
}
</script>
