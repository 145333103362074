<template>
  <div style="position: relative; min-height: calc(100vh - 100px);">
    <v-tabs
        v-model="tabs.index"
        background-color="#f1f1f1"
        next-icon="mdi-arrow-right-bold-box-outline"
        prev-icon="mdi-arrow-left-bold-box-outline"
        show-arrows
        grow
    >
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab v-if="tab.show" v-for="tab in tabs.items" :key="tab.slug">
        {{ tab.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabs.index">
      <v-tab-item
          transition="fade-transition"
          reverse-transition="fade-transition"
          :key="'personal_data'"
      >
        <PersonalDataEdit />
      </v-tab-item>
      <v-tab-item
          transition="fade-transition"
          reverse-transition="fade-transition"
          :key="'contact_person'"
      >
        <ContactPerson />
      </v-tab-item>
      <v-tab-item
          transition="fade-transition"
          reverse-transition="fade-transition"
          :key="'contact_person_document'"
      >
        <ContactPersonDocument />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import PersonalDataEdit from "./CustomerCRMPersonalDataEdit";
import ContactPerson from "./CustomerCRMPersonalDataContactPerson";
import ContactPersonDocument from "./CustomerCRMPersonalDataDocument";

export default {
  name: "CustomerCRMPersonalData",
  components: {PersonalDataEdit, ContactPerson, ContactPersonDocument},
  data() {
    return {
      tabs: {
        index: 0,
        items: [
          {slug: 'personal_data', name: 'Persönliche Daten & Einstellungen', show: true},
          {slug: 'contact_person', name: 'Ansprechpartner', show: this.mxware.can('customer-contact-person-show')},
          {slug: 'contact_person_document', name: 'Dokumente', show: true},
        ],
      },
    }
  }
}
</script>

