<template>
  <v-dialog v-model="dialog" persistent style="z-index: 999999;" :max-width="getWidth">
    <v-card elevation="0" class="pt-4 pb-8 px-2">
      <v-card-title class="headline mb-9">
        {{ $t('customer.crm.company.faq.question.field.title') }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
                v-model="form.title"
                outlined
                hide-details
                :label="$t('customer.crm.company.faq.question.field.title')"
                :placeholder="$t('customer.crm.company.faq.question.field.title')"
                required
                :error="errors.title!==undefined"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-textarea
                v-model="form.text"
                outlined
                hide-details
                :label="$t('customer.crm.company.faq.question.field.text')"
                :placeholder="$t('customer.crm.company.faq.question.field.text')"
                required
                :error="errors.text!==undefined"
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-textarea
                v-model="form.notice"
                outlined
                hide-details
                :label="$t('customer.crm.company.faq.question.field.notice')"
                :placeholder="$t('customer.crm.company.faq.question.field.notice')"
                required
                :error="errors.notice!==undefined"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end mr-2">
        <v-btn :loading="btn_disable" :disabled="btn_disable" class="mr-2" color="green white--text" @click="customerAccessDataCreate" elevation="1" large raised tile>
          {{ $t('customer.buttons.save') }}
        </v-btn>
        <v-btn color="grey white--text" @click="dialogClose" elevation="1" large raised tile>
          {{ $t('customer.buttons.abort') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CCRMCompanyAddressCreate",
  props: {
    open: {
      type: Boolean
    },
    faq_id: {
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      btn_disable: false,
      form: {
        faq_id: null,
        title: '',
        text: '',
        notice: ''
      },
      errors: []
    }
  },
  watch: {
    open() {
      this.dialog = true
      this.form.faq_id = this.faq_id
    },
  },
  computed: {
    getWidth() {
      return window.innerWidth <= 1200 ? '90%' : '1100px'
    }
  },
  methods: {
    dialogClose() {
      this.dialog = false
      this.btn_disable = false
      this.form = {
        faq_id: null,
        title: '',
        text: '',
        notice: ''
      }
    },
    customerAccessDataCreate() {
      this.btn_disable = true

      this.$store.dispatch('companyFaqQuestionCreate', this.form).then(() => {
        this.$emit('result', this.form)
        this.dialogClose()
      }).catch(error => {
        this.btn_disable = false
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;
        }
      })
    }
  }
}
</script>
