<template>
  <v-card elevation="0" class="pb-10 px-2">
    <v-row class="mb-0">
      <v-col cols="12">
        <h3>{{ $t('customer.crm.company.faq.title') }}</h3>
      </v-col>
      <v-col cols="12" class="d-flex align-center">
        <v-text-field
            class="mt-2"
            v-model="faq.welcome_text"
            outlined
            hide-details
            :label="$t('customer.crm.company.faq.welcome_text')"
            :placeholder="$t('customer.crm.company.faq.welcome_text')"
            required
            @input="watch"
        />
        <v-btn v-if="!btn_disable" height="58" x-large class="mt-2 ml-3" color="primary white--text" elevation="0" @click="faqUpdate">
          {{ $t('customer.crm.company.faq.save') }}
        </v-btn>
        <v-btn v-if="!btn_disable" height="58" x-large class="mt-2 ml-3"  elevation="0" @click="afqAbort">
          {{ $t('customer.crm.company.faq.abort') }}
        </v-btn>
      </v-col>

      <v-divider class="ma-5"></v-divider>

      <v-col cols="12">
        <h3>{{ $t('customer.crm.company.faq.question.title') }}</h3>
      </v-col>
      <v-col cols="12" class="d-flex align-center">
        <v-text-field
            class="mt-2"
            v-model="search"
            outlined
            hide-details
            :label="$t('customer.search.label')"
            :placeholder="$t('customer.search.placeholder')"
            required
        />
        <v-btn height="58" x-large class="mt-2 ml-3" color="primary white--text" elevation="0" @click="create_dialog = !create_dialog">
          <v-icon size="36" color="white--text">mdi-plus</v-icon>
        </v-btn>
        <v-btn height="58" x-large class="mt-2 ml-3" color="primary white--text" elevation="0" @click="edit_csv_dialog = !edit_csv_dialog">
          CSV
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mx-table-td-p0" :style="`height: ${height}px; overflow-x: auto`">
      <v-col cols="12">
        <v-data-table :headers="headers" :items="array" :search="search" :items-per-page="10">
          <template v-slot:item.text="{ item }">
            <div v-html="brs(item.text)" class="pa-3" style="background: #f6f6f6;"></div>
<!--            <div v-if="item.notice !== null" style="background: #f5e094;" class="pa-3">-->
<!--              {{ item.notice }}-->
<!--            </div>-->
          </template>
          <template v-slot:item.notice="{ item }">
            <div v-html="brs(item.notice)" v-if="item.notice !== null" style="background: #f5e094;" class="pa-3"></div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn text elevation="0" small @click="edit(item)">
              <v-icon size="16" color="blue-grey darken-4">mdi-pencil-outline</v-icon>
            </v-btn>
            <v-btn text elevation="0" small @click="deleteDialog(item)">
              <v-icon size="16" color="blue-grey darken-4">mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <CCRMCompanyFaqCreate @result="createResult" :faq_id="faq.id" :open="create_dialog" />
    <CCRMCompanyFaqEdit @result="editResult" :data_props="selected_item" :faq_id="faq.id" :open="edit_dialog" />
    <CCRMCompanyFaqQuestionCSVCreate @result="editCSVResult" :faq="faq" :open="edit_csv_dialog" />

    <v-row justify="center">
      <v-dialog v-model="delete_dialog" persistent max-width="500">
        <v-card class="pa-4">
          <v-card-title class="headline mb-9">
            <h3 style="text-align: center">
              {{ $t('customer.crm.company.faq.question.prompt.title') }}
            </h3>
          </v-card-title>
          <v-card-actions class="d-flex justify-center">
            <v-btn color="red" dark @click="itemDelete">
              {{ $t('customer.buttons.delete') }}
            </v-btn>
            <v-btn color="grey" dark @click="delete_dialog = false">
              {{ $t('customer.buttons.abort') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-card>
</template>

<script>
import CCRMCompanyFaqCreate from "./CCRMCompanyFaqCreate";
import CCRMCompanyFaqEdit from "./CCRMCompanyFaqEdit";
import CCRMCompanyFaqQuestionCSVCreate from "@/components/Customer/Crm/Company/_faq/CCRMCompanyFaqQuestionCSVCreate";

import _ from "lodash";

export default {
  name: "CCRMCompanyFaq",
  components: {CCRMCompanyFaqCreate, CCRMCompanyFaqEdit, CCRMCompanyFaqQuestionCSVCreate},
  props: {
    company: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      search: '',
      height: '',
      btn_disable: true,
      create_dialog: false,
      edit_dialog: false,
      edit_csv_dialog: false,
      delete_dialog: false,
      headers: [
        { text: this.$t(`customer.crm.company.faq.question.field.title`), value: 'title', width: '250px' },
        { text: this.$t(`customer.crm.company.faq.question.field.text`), value: 'text'},
        { text: this.$t(`customer.crm.company.faq.question.field.notice`), value: 'notice'},
        { text: '', value: 'actions', sortable: false, width: '140px'},
      ],
      array: [],
      selected_item: {},
      faq: {}
    }
  },
  created() {
    this.getFaq()
  },
  methods: {
    brs(text) {
      if (text !== null) {
        return  text.replace(/\n/g, '<br />')
      }
      return text
    },
    watch() {
      this.disabled()
    },
    disabled() {
      this.btn_disable = _.isEqual(this.faq.welcome_text, this.$store.getters.company_faq.welcome_text)
      this.$store.commit('SET_CUSTOMER_CRM_EDIT_DISABLED', !this.btn_disable)
    },
    afqAbort() {
      this.btn_disable = true
      this.faq.welcome_text = this.$store.getters.company_faq.welcome_text
      this.$store.commit('SET_CUSTOMER_CRM_EDIT_DISABLED', !this.btn_disable)
    },
    faqUpdate() {
      this.$store.dispatch('companyFAQUpdate', this.faq).then(() => {
        this.btn_disable = true
        this.$store.commit('SET_CUSTOMER_CRM_EDIT_DISABLED', !this.btn_disable)
      })
    },
    getFaq() {
      this.$store.dispatch('companyFAQ', this.company.id).then(() => {
        this.faq = {...this.$store.getters.company_faq}
        this.array = this.faq.questions
      })
    },
    edit(item) {
      this.selected_item = item
      this.edit_dialog = !this.edit_dialog
    },
    deleteDialog(item) {
      this.selected_item = item
      this.delete_dialog = !this.delete_dialog
    },
    itemDelete() {
      this.$store.dispatch('companyFaqQuestionDelete', this.selected_item.id).then(() => {
        let index = this.array.findIndex(item => item.id === this.selected_item.id)
        this.array.splice(index, 1)
        this.delete_dialog = false
      })
    },
    createResult() {
      let result = this.$store.getters.company_faq_question
      if (result.notice === undefined) {
        result['notice'] = null
      }

      this.array.push(result)
    },
    editResult(result) {
      let index = this.array.findIndex(item => item.id === result.id)
      this.$set(this.array, index, result)
    },
    editCSVResult(e) {
      this.array = e.data.data
    }
  }
}
</script>
