<template>
  <v-card class="px-4 pt-6 pb-8">
    <v-card-title class="headline mb-9">
      <h3 style="text-align: center; word-break: break-word;">Ansprechpartner</h3>
    </v-card-title>
    <v-card-text>
      <v-row v-if="errors">
        <v-col v-if="errors.status === 500">
          <v-alert dense outlined type="error">
            Alle Felder müssen ausgefüllt werden
          </v-alert>
        </v-col>

        <v-col v-if="errors.uniq_contact_error">
          <v-alert dense outlined type="error">
            Dieser Mitarbeiter ist bereits in der Ansprechpartnerliste
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="7">
          <v-autocomplete
              v-model="autocomplete_watcher"
              :items="items"
              :loading="isLoading"
              :search-input.sync="search"

              clearable
              hide-details
              hide-selected
              itemscope
              :item-text="search_item_text === 0 ? 'first_name' : 'last_name'"
              item-value="id"
              :label="$t('employee.crm.search.employee_search_label')"
              solo
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('customer.crm.search.customer_search_info') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ attr, on, item, selected }">
              <span>{{ item.first_name }} {{ item.last_name }}</span>
            </template>

            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.first_name }} {{ item.last_name }} / <small
                    style="color: #b7b7b7">{{ item.position }}</small></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col class="mt-n1" cols="12" md="2">
          <small>{{ $t('customer.crm.search.user.title') }}</small>
          <v-btn-toggle v-model="search_item_text" mandatory>
            <v-btn small>
              {{ $t('customer.crm.search.user.first_name') }}
            </v-btn>
            <v-btn small>
              {{ $t('customer.crm.search.user.last_name') }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="d-flex">
          <v-text-field v-model="form.first_name" outlined hide-details disabled required :error="errors.first_name!==undefined"
                        :label="$t('employee.contact_person.first_name') + ' *'" :placeholder="$t('employee.contact_person.first_name')"></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="d-flex">
          <v-text-field v-model="form.last_name" outlined hide-details disabled required
                        :label="$t('employee.contact_person.last_name') + ' *'" :placeholder="$t('employee.contact_person.last_name')"></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-select v-model="form.gender"
                    :label="$t('employee.gender.title') + ' *'"
                    :items="gender_select"
                    outlined
                    disabled
                    hide-details
                    :append-icon="'mdi-chevron-down'"
                    :menu-props="{ bottom: true, offsetY: true }"
          ></v-select>
        </v-col>
        <v-col cols="12" md="6" class="d-flex">
          <v-text-field v-model="form.contact_email" outlined hide-details required
                        :label="$t('employee.contact_person.email') + ' *'" :placeholder="$t('employee.contact_person.email')"></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="d-flex">
          <v-text-field v-model="form.contact_phone" outlined hide-details required
                        :label="$t('employee.contact_person.phone') + ' *'" :placeholder="$t('employee.contact_person.phone')"></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="d-flex">
          <v-text-field v-model="form.position" outlined hide-details required
                        :label="$t('employee.contact_person.position') + ' *'" :placeholder="$t('employee.contact_person.position')"></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="d-flex justify-end mr-2">
      <v-btn color="green" :disabled="btn_disable" @click="contactAdd">{{ $t('customer.buttons.save') }}</v-btn>
      <v-btn color="grey" dark @click="dialogClose">{{ $t('customer.buttons.abort') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "CustomerCRMContactPersonCreateForm",
  props: {
    contact_persons: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      btn_disable: true,
      items: [],
      search: null,
      autocomplete_watcher: null,
      search_item_text: 0,
      errors: {},
      form: {
        employee_uid: '',
        customer_id: this.$route.params.id,
        first_name: '',
        last_name: '',
        gender: '',
        contact_email: '',
        contact_phone: '',
        position: '',
        avatar: null
      },
      gender_select: [
        {
          text: this.$t('customer.gender.female'),
          value: 'female'
        },
        {
          text: this.$t('customer.gender.male'),
          value: 'male'
        },
        {
          text: this.$t('customer.gender.divers'),
          value: 'divers'
        },
        {
          text: this.$t('customer.gender.indefinite'),
          value: 'indefinite'
        },
      ],
    }
  },
  watch: {
    autocomplete_watcher(id) {
      if (id === null) {
        return
      }

      if (this.contact_persons.find(c => c.employee_uid === id) !== undefined) {
        this.errors = {uniq_contact_error: true}
        this.btn_disable = true
      } else {
        const item = this.items.find(item => item.id === id)

        this.form.employee_uid = item.id
        this.form.first_name = item.first_name
        this.form.last_name = item.last_name
        this.form.gender = item.gender
        this.form.contact_email = item.email
        this.form.position = item.position
        this.form.avatar = item.avatar

        this.btn_disable = false
        this.errors = {uniq_contact_error: false}
      }
    }
  },
  created() {
    this.getItems()
    setTimeout(() => {
      this.preloader = false
    }, 300)
  },
  methods: {
    getItems() {
      this.$store.dispatch('employees').then(() => {
        this.items = this.$store.getters.employees.data
        this.isLoading = false
      })
    },
    dialogClose() {
      this.$emit('dialogClose', false)
    },
    contactAdd() {
      this.$store.dispatch('contactPersonCreate', this.form).then(() => {
        this.$emit('getPerson', this.$store.getters.customerContactPerson)
        this.$emit('dialogClose', false)
      }).catch(error => {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            this.snackbar_error = true
            break;
          case 500:
            this.errors = {status: 500, messages: ''};
            this.snackbar_error = true
            break;
        }
      })

    }
  }
}
</script>
