<template>
  <div class="mx-table-ttu" style="min-height: calc(100vh - 50px)">
    <mx-preloader :loader-status="preloader"/>
    <v-tabs v-model="top_menu.index" background-color="#cecece" show-arrows>
      <v-tab v-if="tab.show" :disabled="$store.getters.customerCrmEditDisabled" v-for="tab in top_menu.tabs" :key="tab.slug">
        {{ tab.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="top_menu.index">
      <v-tab-item
          transition="fade-transition"
          reverse-transition="fade-transition"
          :key="'personal_data'"
      >
        <PersonalData />
      </v-tab-item>
      <v-tab-item
          transition="fade-transition"
          reverse-transition="fade-transition"
          :key="'companies'"
          v-if="mxware.can('customer-company-show')"
      >
        <Companies />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import PersonalData from "@/components/Customer/Crm/PersonalData/CustomerCRMPersonalData";
import Companies from "@/components/Customer/Crm/Company/_company/CustomerCRMCompanies";

export default {
  components: {PersonalData, Companies},
  data() {
    return {
      preloader: true,
      top_menu: {
        index: 0,
        tabs: [
          {slug: 'personal_data', name: 'Kunde', show: true},
          {slug: 'companies', name: 'Unternehmen', show: this.mxware.can('customer-company-show')},
        ],
      },
    }
  },
  created() {
    setTimeout(() => {this.preloader = false}, 300)
  },
}
</script>

<style lang="scss">

.mx-table-ttu {
  .v-tab {
    text-transform: unset;
  }
}

</style>
