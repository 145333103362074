<template>
  <div style="position: relative; min-height: calc(100vh - 100px);">
    <mx-preloader :loader-status="preloader"/>
    <v-tabs v-model="company.index" background-color="#f1f1f1" show-arrows>
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab :disabled="$store.getters.customerCrmEditDisabled" v-for="tab in company.tabs" :key="tab.id">
        {{ tab.company_name }}
      </v-tab>
      <div :hidden="$store.getters.customerCrmEditDisabled" @click="company_create_dialog = !company_create_dialog">
        <span class="mx-btn-create-out">+</span>
      </div>
    </v-tabs>
    <v-tabs-items v-model="company.index">
      <v-tab-item
          transition="fade-transition"
          reverse-transition="fade-transition"
          v-for="item in company.tabs"
          :key="item.id"
      >
        <CCRMCompany @companyDelete="companyAdd" :company="item" />
      </v-tab-item>
    </v-tabs-items>

    <CCRMCompanyCreateDialog @companyAdd="companyAdd" :open="company_create_dialog" />

  </div>
</template>

<script>
import CCRMCompany from "./CCRMCompany";
import CCRMCompanyCreateDialog from "../_company/CCRMCompanyCreate";

export default {
  name: "CustomerCRMCompanies",
  components: {CCRMCompany, CCRMCompanyCreateDialog},
  data() {
    return {
      preloader: true,
      company_create_dialog: false,
      company: {
        index: 0,
        tabs: [],
      },
    }
  },
  created() {
    this.getCompanies()
  },
  methods: {
    getCompanies() {
      if (this.$store.getters.customerCrm === undefined) {
        console.log("customerCrm === undefined");
        setTimeout( this.getCompanies, 1000)
      }

      this.$store.dispatch('companiesFromCustomer', this.$store.getters.customerCrm.customer.id).then(() => {
        this.company.tabs = this.$store.getters.companies

        if (this.company.tabs === undefined) {
          setTimeout( this.getCompanies, 1000)
        } else {
          this.preloader = false
        }
      })

    },
    companyAdd() {
      this.company.tabs = this.$store.getters.companies
    }
  }
}
</script>
