<template>
  <v-dialog v-model="dialog" persistent style="z-index: 999999;" :max-width="getWidth">
    <v-card elevation="0" class="pt-4 pb-8 px-2">
      <v-card-title class="headline mb-9">
        {{ $t('customer.crm.company.cad.subtitle') }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
                v-model="form.title"
                outlined
                hide-details
                :label="$t('customer.crm.company.cad.title')"
                :placeholder="$t('customer.crm.company.cad.title')"
                required
                :error="errors.title!==undefined"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
                v-model="form.description"
                outlined
                hide-details
                :label="$t('customer.crm.company.cad.description')"
                :placeholder="$t('customer.crm.company.cad.description')"
                required
                :error="errors.description!==undefined"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider class="my-6"></v-divider>
        <v-row>
          <v-col cols="12">
            <v-text-field
                v-model="form.url"
                outlined
                hide-details
                :label="$t('customer.crm.company.cad.url')"
                :placeholder="$t('customer.crm.company.cad.url')"
                required
                :error="errors.url!==undefined"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
                v-model="form.login"
                outlined
                hide-details
                :label="$t('customer.crm.company.cad.login')"
                :placeholder="$t('customer.crm.company.cad.login')"
                required
                :error="errors.login!==undefined"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
                v-model="form.password"
                outlined
                hide-details
                :label="$t('customer.crm.company.cad.password')"
                :placeholder="$t('customer.crm.company.cad.password')"
                required
                :error="errors.password!==undefined"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>

        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between mr-2">
        <v-checkbox
            class="ml-2"
            v-model="form.is_active"
            :label="$t('customer.crm.company.cad.is_active.title')"
            :false-value="0"
            :true-value="1"
        ></v-checkbox>
        <div>
          <v-btn :loading="btn_disable" :disabled="btn_disable" class="mr-2" color="green white--text" @click="customerAccessDataCreate" elevation="1" large raised tile>
            {{ $t('customer.buttons.save') }}
          </v-btn>
          <v-btn color="grey white--text" @click="dialogClose" elevation="1" large raised tile>
            {{ $t('customer.buttons.abort') }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CCRMCompanyAddressCreate",
  props: {
    open: {
      type: Boolean
    },
    company: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      btn_disable: false,
      form: {
        company_id: this.company.id,
        title: '',
        description: '',
        url: '',
        login: '',
        password: '',
        is_active: 1,
      },
      errors: []
    }
  },
  watch: {
    open() {
      this.dialog = true
    },
  },
  computed: {
    getWidth() {
      return window.innerWidth <= 1200 ? '90%' : '1100px'
    }
  },
  methods: {
    dialogClose() {
      this.dialog = false
      this.btn_disable = false
      this.form = {
        company_id: this.company.id,
        title: '',
        description: '',
        url: '',
        login: '',
        password: '',
        is_active: 1,
      }
    },
    customerAccessDataCreate() {
      this.btn_disable = true
      this.$store.dispatch('companyCustomerAccessDataCreate', this.form).then(() => {
        this.$emit('result', this.form)
        this.dialogClose()
      }).catch(error => {
        this.btn_disable = false
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;
        }
      })
    }
  }
}
</script>
